//******************************************************************************
// SECTIONS
// Styles for the various sections used throughout the site
//******************************************************************************

// SECTION
// -----------------------------------------------------------------------------

$pbPadding: 8rem;
$pbPadding-tablet: 6rem;

.clip {
	transition: clip-path .2s ease;

	img {
		min-width: 100%;
	}

	&--top-right--sm {
		-webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 60px, calc(100% - 60px) 0%);
  		clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 60px, calc(100% - 60px) 0%);
	}
	&--top-right--lg {
		-webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 120px, calc(100% - 120px) 0%);
  		clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 120px, calc(100% - 120px) 0%);
	}
	&--bottom-left--sm {
		-webkit-clip-path: polygon(100% 0%, 100% 100%, 60px 100%, 0% calc(100% - 60px), 0% 0%);
		clip-path: polygon(100% 0%, 100% 100%, 60px 100%, 0% calc(100% - 60px), 0% 0%);
	}
	&--bottom-left--lg {
		-webkit-clip-path: polygon(100% 0%, 100% 100%, 120px 100%, 0% calc(100% - 120px), 0% 0%);
		clip-path: polygon(100% 0%, 100% 100%, 120px 100%, 0% calc(100% - 120px), 0% 0%);
	}
	&--bottom-right--sm {
		-webkit-clip-path: polygon(100% 0%, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0% 100%, 0% 0%);
		clip-path: polygon(100% 0%, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0% 100%, 0% 0%);
	}
	&--bottom-right--lg {
		-webkit-clip-path: polygon(100% 0%, 100% calc(100% - 120px), calc(100% - 120px) 100%, 0% 100%, 0% 0%);
		clip-path: polygon(100% 0%, 100% calc(100% - 120px), calc(100% - 120px) 100%, 0% 100%, 0% 0%);
	}
	&--both--sm {
		-webkit-clip-path: polygon(calc(100% - 60px) 0%, 100% 60px, 100% 100%, 60px 100%, 0% calc(100% - 60px), 0% 0%);
		clip-path: polygon(calc(100% - 60px) 0%, 100% 60px, 100% 100%, 60px 100%, 0% calc(100% - 60px), 0% 0%);
	}
	&--both--lg {
		-webkit-clip-path: polygon(calc(100% - 120px) 0%, 100% 120px, 100% 100%, 120px 100%, 0% calc(100% - 120px), 0% 0%);
		clip-path: polygon(calc(100% - 120px) 0%, 100% 120px, 100% 100%, 120px 100%, 0% calc(100% - 120px), 0% 0%);
	}

	&--animated {
		clip-path: polygon(calc(100% - 120px) 0,100% 120px,100% 100%,0 100%,0 calc(100% - 120px),0 0);
	}

	@include media-breakpoint-only(md) {
		&--top-right--lg {
			-webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 100px, calc(100% - 100px) 0%);
	  		clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 100px, calc(100% - 100px) 0%);
		}
		&--bottom-left--lg {
			-webkit-clip-path: polygon(100% 0%, 100% 100%, 100px 100%, 0% calc(100% - 100px), 0% 0%);
			clip-path: polygon(100% 0%, 100% 100%, 100px 100%, 0% calc(100% - 100px), 0% 0%);
		}
		&--bottom-right--lg {
			-webkit-clip-path: polygon(100% 0%, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0% 100%, 0% 0%);
			clip-path: polygon(100% 0%, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0% 100%, 0% 0%);
		}
		&--both--lg {
			-webkit-clip-path: polygon(calc(100% - 100px) 0%, 100% 100px, 100% 100%, 100px 100%, 0% calc(100% - 100px), 0% 0%);
			clip-path: polygon(calc(100% - 100px) 0%, 100% 100px, 100% 100%, 100px 100%, 0% calc(100% - 100px), 0% 0%);
		}
		&--animated {
			clip-path: polygon(calc(100% - 100px) 0,100% 100px,100% 100%,0 100%,0 calc(100% - 100px),0 0);
		}
	}

	@include media-breakpoint-only(sm) {
		&--both--lg {
			-webkit-clip-path: polygon(calc(100% - 80px) 0%, 100% 80px, 100% 100%, 80px 100%, 0% calc(100% - 80px), 0% 0%);
			clip-path: polygon(calc(100% - 80px) 0%, 100% 80px, 100% 100%, 80px 100%, 0% calc(100% - 80px), 0% 0%);
		}
		&--animated {
			clip-path: polygon(calc(100% - 80px) 0,100% 80px,100% 100%,0 100%,0 calc(100% - 80px),0 0);
		}
	}

	@include media-breakpoint-only(xs) {
		&--both--lg {
			-webkit-clip-path: polygon(calc(100% - 60px) 0%, 100% 60px, 100% 100%, 60px 100%, 0% calc(100% - 60px), 0% 0%);
			clip-path: polygon(calc(100% - 60px) 0%, 100% 60px, 100% 100%, 60px 100%, 0% calc(100% - 60px), 0% 0%);
		}
		&--animated {
			clip-path: polygon(calc(100% - 60px) 0,100% 60px,100% 100%,0 100%,0 calc(100% - 60px),0 0);
		}
	}

	@include media-breakpoint-between(xs, sm) {
		&--top-right--lg {
			-webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 60px, calc(100% - 60px) 0%);
	  		clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 60px, calc(100% - 60px) 0%);
		}
		&--bottom-left--lg {
			-webkit-clip-path: polygon(100% 0%, 100% 100%, 60px 100%, 0% calc(100% - 60px), 0% 0%);
			clip-path: polygon(100% 0%, 100% 100%, 60px 100%, 0% calc(100% - 60px), 0% 0%);
		}
		&--bottom-right--lg {
			-webkit-clip-path: polygon(100% 0%, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0% 100%, 0% 0%);
			clip-path: polygon(100% 0%, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0% 100%, 0% 0%);
		}
	}
}

//SITEWIDE - INPAGE PULL OUT SHORTCODE
.pull-out {
	margin-bottom: 3rem;
	padding: 4rem;

	.icon {
		display: block;
		margin: 0 auto 2rem;
	}
	.link {
		margin-top: 2rem;
	}

	p:last-of-type {
		margin-bottom: inherit;
	}

	a {
		color: inherit;
	}
	//a,
	&__link {
		@include fluid-type(1.8rem, 2.2rem);
		font-family: $heading;
		line-height: 1;
		font-weight: 500;
		display: block;
		margin-top: 1.1rem;
		color: inherit;
		&:hover {
			color: inherit;
		}
		&:first-of-type {
			margin-top: 3rem;
		}
	}

	&__img {
		margin-bottom: 2rem;
	}

	&--large-cta {
		margin-top: 0;
		padding: 8rem;
	}

	&--image-cta {
		padding: 0 4rem 4rem 4rem;
	}

	&--front {
		margin-top: 18rem;
		padding: 7rem;
		.pull-out__image {
			display: block;
			margin: 0 auto 2rem;
			width: 160px;
			.pull-out__img {
				width: 100%;
				height: auto;
			}
		}
	}

	&--dashboard {
		p {
			font-weight: 400;
			strong {
				font-weight: 500;
			}
		}
		padding: 6rem 6rem 8rem 6rem;
		.pull-out__image {
			display: block;
			margin: 0 auto 2rem;
			width: 260px;
			.pull-out__img {
				width: 100%;
				height: auto;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		max-width: 50rem;
	}

	@include media-breakpoint-between(xs,md) {
		&--image-cta,
		&--dashboard {
			max-width: unset;
			.pull-out__image {
				display: none;
			}
		}
		&--front {
			max-width: unset;
		}
		&--image-cta {
			margin-top: 5rem;
		}
	}

	@include media-breakpoint-between(xs,md) {
		padding: 5rem 2.5rem;
		margin-bottom: 2rem;
		&--front {
			margin-bottom: 0;
			margin-top: 2rem; 
		}
	}
}

//SITEWIDE - CARDS 
.cards {
	margin-top: 5rem;
	&.carousel {
		margin-top: 10rem;
	}

	// Homepage
	&--front {
		margin-top: 12rem;
	}

	@include media-breakpoint-only(md) {
		margin-top: 5rem;
		&.carousel {
			margin-top: 10rem;
		}
	}

	@include media-breakpoint-between(xs,sm) {
		margin-top: 5rem;
		&.carousel {
			margin-top: 8rem;
		}
	}
}
.card {
	.card__outer {
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	.card__inner {
		position: relative;
		height: 100%;
		padding: 4rem 2rem;
	}
	.link {
		margin-top: 2rem;
	}
	.card__icon {
		margin-bottom: 2.4rem;
		img {
			max-height: 48px;
			width: auto;
		}
	}
	.card__image {
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		max-height: 200px;
		overflow: hidden;
		height: auto;
	}

	&--team-members {
		.card__image {
			max-height: 30rem;
		}
	}

	// Homepage
	&--slider {
        .card__inner {
            padding: 4rem 2rem 4rem 3rem;
        }
        .link {
            margin-top: 7rem;
        }
    }
	&--list {
		margin-bottom: 8rem;
	}

	@include media-breakpoint-between(xs,md) {
		margin-bottom: 2rem;
		&--list {
			margin-bottom: 2rem;
		}

		.card__outer.bg-white {
			.card__inner {
				padding: 0 2rem;
			}
		}
	}
}
.card__meta {
	font-size: 1.4rem;
	margin-bottom: 2rem;
	a {
		color: inherit;
	}
}

//SITEWIDE - VIDEO
.video-container {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
    	background-color: #000;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .btn-video-play {
    	width: 100%;
	    height: 100%;
	    position: absolute;
	    font-size: 0;
	    border: 0;
    	background: transparent;
    	&:focus {		
			outline: none;
	  		box-shadow: none;
		}
	    &:before {
	    	position: absolute;
	    	content: '';
	    	z-index: 1;
	    	width: 0;
			height: 0;
			margin-left: 2px;
			border-style: solid;
			border-width: 9px 0 9px 12px;
			border-color: transparent transparent transparent $font-clr;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	    &:after {
	    	position: absolute;
	    	content: '';
	    	width: 80px;
	    	height: 80px;
	    	border-radius: 80px;
	    	display: block;
	    	background: $bg-gold;
	    	top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
	    }
	    &:hover {
	    	&:before {
	    		border-color: transparent transparent transparent white;
	    	}
	    	&:after {
	    		background: $font-clr;
	    	}
	    }
    }

    @include media-breakpoint-between(xs,sm) {
    	.btn-video-play {
    		&:before {
				border-width: 7px 0 7px 10px;
			}
		    &:after {
		    	width: 60px;
		    	height: 60px;
		    	border-radius: 60px;
		    }
    	}
    }
}

//SITEWIDE - PAGEBUILDER
.pb {
	padding-top: $pbPadding;
	padding-bottom: $pbPadding;

	@include media-breakpoint-down(md) {
		padding-top: $pbPadding-tablet;
		padding-bottom: $pbPadding-tablet;
	}

	h1, h2, h3, h4 {
		margin-bottom: 0;
	}

	h2 + h3, h2 + h4,
	h3 + h2, h3 + h4,
	h4 + h2, h4 + h3,
	p + h2, p + h3, p + h4,
	ul + h2, ul + h3, ul + h4,
	ol + h2, ol + h3, ol + h4 {
		margin-top: 4rem;
	}

	&:not(.bg-white) {
		margin-top: $pbPadding;

		@include media-breakpoint-down(md) {
			margin-top: $pbPadding-tablet;
		}
	}
	&:not(.bg-white, .bg-stone) {
		.cta_button,
		.btn {
			border: 1px solid $font-clr;
			background-color: transparent;
			&:hover {
				color: white;
				background-color: $font-clr;
			}
		}
	}

	&.bg-white {
		padding-bottom: 0;
	}

	&:last-of-type {
		&.bg-white {
			margin-bottom: $pbPadding;

			@include media-breakpoint-down(md) {
				margin-bottom: $pbPadding-tablet;
			}

			@include media-breakpoint-down(sm) {
				margin-bottom: 5rem;
			}
		}
	}

	&--keyline {
		margin-top: $pbPadding;

		// ICON QUICK LINKS ON SINGLE.PHP
		.row {
			&.cards {
				margin-top: 0;
				&:before {
					content: '';
					display: block;
					height: 1px;
					background-color: $bg-stone;
					width: 100%;
					position: relative;
		    		top: -$pbPadding;
		    		margin-left: 15px;
		    		margin-right: 15px;
				}
			}
		}

		// MARKETING FORM ON SINGLE.PHP
		.container {
			&:before {
				content: '';
				display: block;
				height: 1px;
				background-color: $bg-stone;
				width: 100%;
				position: relative;
	    		top: -$pbPadding;
			}
		}

		/* Portrait and Landscape iPad */
		@media only screen 
		  and (min-device-width: 768px) 
		  and (max-device-width: 1024px) 
		  and (-webkit-min-device-pixel-ratio: 1) {
			margin-top: 9rem;
		}
	}

	@include media-breakpoint-only(md) {
		&--keyline {
			margin-top: 9rem;
			padding-top: 9rem;
			.row {
				&.cards {
					&:before {
			    		top: -9rem;
					}
				}
			}
			.container {
				&:before {
		    		top: -9rem;
				}
			}
		}
	}
	@include media-breakpoint-between(xs,sm) {
		padding-top: 5rem;
		padding-bottom: 5rem;
		&:not(.bg-white) {
			margin-top: 5rem;
		}
		&--keyline {
			margin-top: 5rem;
			padding-top: 5rem;
			.row {
				&.cards {
					&:before {
			    		top: -5rem;
					}
				}
			}
			.container {
				&:before {
		    		top: -5rem;
				}
			}
		}
	}
}

.pb-hero.pb-hero {
	margin-top: $menu-height;

	&.has-header-sitewide-cta {
		margin-top: 24rem;

		@include media-breakpoint-between(xs,md) {
			margin-top: 0;
		}

	}

	@include media-breakpoint-between(xs,md) {
		margin-top: 0;
	}

}

//HERO
.pb-hero {
	overflow: hidden;
	.container {
		display: block !important;
	}

	.row {
		display: flex !important;
		//align-items: center;
	}

	h1,
	h2 {
		margin-top: 7rem;
		margin-bottom: 4rem;
	}

	.hero__inner {
		position: relative;
		z-index: 5;
		margin-top: auto;
		margin-bottom: auto;
	}

	&--extra-padding {
		.pb-hero__left {
			padding-bottom: 15rem;

			@include media-breakpoint-down(sm) {
				padding-bottom: 3rem;
			}
		}
	}

	&__left,
	&__right {
		position: relative;
		a {
			color: inherit;
		}
	}

	&__right {
		.objectfit {
			position: relative;
		}
	}

	&__left {	
		padding-top: 2rem;
		padding-bottom: 10rem;
		z-index: 1;
		display: flex;
		flex-direction: column;
		p {
			margin-bottom: 2rem;
		}
	}

	&--slider {
		.link {
			margin-top: 4rem;
		}
	}

	&--small {
		h1 {
			margin-top: 10rem;
		    margin-bottom: 10rem;
		}
	}

	&--contact {
		padding: 27rem 0 10rem;
    	margin-top: -20rem;
    	
		.pb-hero__left {
			padding-top: 0;
			padding-bottom: 0;
			h1 {
				margin-bottom: 4rem;
			}
			small {
				margin-top: 2rem;
				margin-bottom: 5rem;
			}
		}
		.pb-hero__right {
			.form {
				margin-top: 3rem;
				z-index: 1;
	    		position: relative;
			}
		}
	}

	&--error {
		color: white;
		h1 {
			margin-bottom: 4rem;
		}
		p {
			margin-bottom: 2rem;
		}
		.link {
			color: white;
			&:before {
	            content: url('../assets/images/arrow-icon-white.svg');
	        }
		}
	}

	&--alumni {
		// padding-bottom: 10rem;
		overflow: visible;
		.container {
			position: relative;
		}
		h1 {
			margin-bottom: 9rem;
		}
	}

	&__extra-info-block:not(:last-of-type) {
		margin-bottom: 3rem;
	}
	
	@include media-breakpoint-down(lg) {
		&--contact {
			margin-top: 4rem;
		}
	}

	@include media-breakpoint-only(md) {
		&--error {
			.error__content {
				padding-top: 12rem;
				padding-bottom: 12rem;
			}
		}
		&--contact {
			padding-bottom: 18rem;
		}
	}

	@include media-breakpoint-between(xs,sm) {
		&--contact {
			padding-bottom: 5rem;
		}
	}

	@include media-breakpoint-between(xs,md) {
		h1,
		h2 {
			margin-top: 0;
			margin-bottom: 4rem;
		}
		&--slider {
			.hero__inner {
				padding-right: 3rem;
			}
			h1,
			h2 {
				margin-bottom: 0;
			}
			.link {
				margin-top: 2.5rem;
			}
		}
		&__left {	
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
		&__right {
			padding-left: 0;
			padding-right: 0;
		}
		&--small {
			h1 {
				padding-top: 4rem;
			}
		}
		&--contact {
			padding-top: 5rem;
			.pb-hero__right {
				padding-top: 5rem;
				padding-left: 15px;
    			padding-right: 15px;
			}
		}
		&--error {
			.error__content {
				padding-top: 5rem;
				padding-bottom: 5rem;
			}
		}
	}


	/* Portrait iPad */
	@media only screen 
	  and (min-device-width: 768px) 
	  and (max-device-width: 1024px) 
	  and (orientation: portrait) 
	  and (-webkit-min-device-pixel-ratio: 1) {
		h1, .alpha {
			font-size: 4rem;
			line-height: 1.2;
		}
	}

	/* Portrait and Landscape iPad */
	@media only screen 
	  and (min-device-width: 768px) 
	  and (max-device-width: 1024px) 
	  and (-webkit-min-device-pixel-ratio: 1) {
		div.objectfit img {
			object-fit: contain;
		}
	}




}

//GENERIC TEXT BLOCK
.pb-generic-text {
	&__content {
		.pull-out {
			max-width: 400px;
		}
	}

	&__eprints-meta {
		margin-bottom: 2rem;

		// author ORCID in hero
		a[href^="https://orcid.org"],
		a[href^="http://orcid.org"] {
			position: relative;
			padding-right: 3rem;
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				background-image: url('../assets/images/icon-orcid.svg');
				background-size: contain;
				background-repeat: no-repeat;
				width: 22px;
				height: 22px;
			}
		}

	}

	&__eprints-download {
		.btn {
			margin-top: 2rem;
		}
	}

	&__eprints-copyright {
		// margin-top: 2rem;
	}

	&__eprints-authors {
		p {
			display: inline;
		}

		ul {
			display: inline-block;
			list-style: none;
			margin: 0 !important;
			padding: 0 !important;
			
			li {
				display: block;
				margin-bottom: 0.5rem;

				&:last-child {
					margin-bottom: 0;
				}
				
			}

		}

	}

}

.page-default-content,
.pb-generic-text__content {
	ul,ol {
		margin-top: 2rem;
		padding-left: 2rem;
	}

	p + ul,
	p + ol {
		margin-top: 2rem;
	}

	li:not(:first-of-type) {
		margin-top: 2rem;
	}

	ul.smaller-gap li:not(:first-of-type) {
		margin-top: 1rem;
	}

}

@include media-breakpoint-between(xs,md) {
	.bg-white {
		.pb-generic-text__content {
			margin-bottom: 5rem;
		}
	}
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
	.pb-generic-text.bg-white {
		.pb-generic-text__content {
			margin-bottom: 0;
		}
	}
}

//LARGE CTA WITH IMAGE/VIDEO LEFT/RIGHT
.pb-large-cta {}

//QUICK LINKS
.pb-quick-links {
	h2 {
		margin-bottom: 5rem;
	}
	.cards {
		margin-top: 0;

		@include media-breakpoint-down(sm) {
			.card:not(:last-of-type) {
				margin-bottom: 7rem;
			}
		}
	}
}

//BANNER CTA
.pb-banner-cta {
	background-repeat: no-repeat;
	background-position: center;
	.btn {
		margin-top: 2rem;
	}
}

//CARDS BLOCK
.pb-cards-block {
	.cards {
		margin-top: 2rem;
		
		.card {
			margin-top: 3rem;
		}
	}

	@include media-breakpoint-down(sm) {
		&--image .pb-block-header {
			margin-bottom: 2rem;
			text-align: left !important;
		}
	}
}

//KEY DETAILS BLOCK
.pb-key-details {
	.clip {
		padding: 6rem;
	}
	h2 {
		margin-bottom: 4rem;
	}
	h4 {
		margin-bottom: 0;
	}
	.cards {
		margin-top: 0;
	}
	.card {
		margin-top: 2rem;
		&:nth-child(-n+3) {
			margin-top: 0;
		}
		.link {
			margin-top: 1.5rem;
		}
	}
	@include media-breakpoint-only(md) {
		.clip {
			padding: 4rem;
		}
		.card {
			margin-top: 0;
		}
	}
	@include media-breakpoint-between(xs,sm) {
		.clip {
			padding: 4rem 2rem 6rem 2rem;
		}
		.card {
			margin-bottom: 2rem;
			&:nth-child(-n+3) {
				margin-top: 2rem;
			}
		}
	}
}

//FEATURES AND SKILLS
.pb-features-skills-table {
	ul {
		font-size: 1.7rem;
	    padding-left: 0;
	    margin-bottom: 1.1em;
	    list-style: none;
	    li {
	    	position: relative;
	        margin-top: 1.1em;
	        padding-left: 2.5rem;
	        &:before {
	        	position: absolute;
	        	left: 0;
	            content: url('../assets/images/tick-icon-gold.svg');
	        }
	    }
	}
	.btn {
		margin-bottom: 2rem;
	}

	@include media-breakpoint-up(md) {
		.feature-skills-slider .slick-next, .feature-skills-slider .slick-prev {
			top: -6rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.pb-block-header {
			text-align: left !important;
		}
	}
}

//COURSE MODULE
.pb-course-modules {
	.tab-buttons {
		display: flex;
		justify-content: space-between;
		margin-bottom: 6rem;
		.nav-tabs {
			border-bottom: 0;
		}
	}
	.nav-links {
		&--desktop {
			display: block;
		}
		&--mobile {
			display: none;
		}
	}
	.tab-content {
		> .tab-pane {
			&.active {
				display: flex;
			}

			.nav-tabs-container {
				width: 45%;
			}

			.nav-button-label,
			.nav-button {
				display: none;
			}

			.nav-links {
				&--desktop {
					display: block;
				}
				&--mobile {
					display: none;
				}
			}

			.nav-tabs,
			.tab-content {
				display: inline-block;
			}
			.nav-tabs {
				// width: 45%;
				width: 100%;
				border-bottom: 0;
				list-style: none;
				li {
					position: relative;
					width: 100%;
					margin-top: 0;
					display: flex;
    				align-items: center;
					border-bottom: 1px solid #E5E5E5;
					&:before {
						display: none;
					}
			    	&:after {
			    		content: url('../assets/images/arrow-icon.svg');
			    		position: absolute;
			    		right: 2rem;
			    	}
					&.active,
					&:hover {
						background: $bg-gold;
						border-bottom: 1px solid $bg-gold;
					}
				}
				a {
					@extend .epsilon;
					padding: 2rem 5rem 2rem 2rem;
					width: 100%;
					font-family: $heading;
					color: $font-clr;
					line-height: 1.2;
					text-decoration: none;
					&.active {
						background: $bg-gold;
						border-bottom: 1px solid $bg-gold;
					}
				}
			}

			.tab-content {
				width: 55%;
				.tab-pane {
					padding: 4rem;
					background-color: #FDEBCB;
					&.active {
						display: block;
						h5 {
							display: block;
							margin: 4rem 0 2rem 0;
							&:first-of-type {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-only(md) {
		.tab-buttons {
			flex-direction: column;
			margin-bottom: 2rem;
			.nav-tabs {
				order: 2;
			}
		}

	}

	@include media-breakpoint-down(md) {
		h5 {
			font-size: 2.4rem;
		}
	}

	@include media-breakpoint-between(xs,sm) {
		.tab-buttons {
			flex-direction: column;
			margin-bottom: 2rem;
			.nav-tabs {
				order: 2;
				justify-content: space-between;
				.btn {
					margin-left: 0;
					min-width: 48%;
					text-align: center;
					display: block;
				}
			}
			.nav-links {
				.btn {
					width: 100%;
					margin-left: 0;
					&:before,
					&:after {
						display: none;
					}
				}
			}
		}

	}

	@include media-breakpoint-between(xs,md) {
		.nav-links {
			&--desktop {
				display: none;
			}
			&--mobile {
				display: block;
				
				.btn {
					margin-left: 0;
				}
			}
		}
		.tab-content {
			> .tab-pane {
				&.active {
				    display: block;
				}
				.nav-tabs-container {
					width: 100%;
					position: relative;
					.nav-tabs {
						display: none;
					}
					&.open {
						background: white;
						// background: $bg-gold-tint;
						.nav-button {
							background: url('../assets/images/up-arrow.png') no-repeat;
							background-position: right 18px center;

						}
						.nav-tabs {
							display: block;
						}
					}
				}

				.nav-button-label {
					display: block;
				}
				
				.nav-button {
					display: block;
					-webkit-appearance: none;
			        -moz-appearance: none;
			        appearance: none;
			        min-height: 55px;
			        font-weight: 400;
			        font-size: 1.7rem;
			        width: 100%;
			        padding: 0.5rem 2rem;
			        display: block;
			        position: relative;
			        text-align: left;
			        z-index: 25;
			        background: url('../assets/images/down-arrow.png') no-repeat;
			        background-position: right 18px center;
			        box-shadow: none  !important;
			        background-color: white;
			        border: 1px solid $font-clr;
			        &:before {
						content: attr(navigation-title);
				    }
				}
				.nav-tabs,
				.tab-content {
					width: 100%;
				}
				.nav-tabs {
					border-bottom: 0;
					list-style: none;
					position: absolute;
					z-index: 10;
				    background: white;
				    border-left: 1px solid;
				    border-right: 1px solid;
				    border-bottom: 1px solid;
				    border-color: $font-clr;
					li {
						border-bottom: none;
				    	&:after {
				    		display: none;
				    	}
						&.active,
						&:hover {
							background: transparent;
							border-bottom: none;
						}
						&:last-of-type {
							border-bottom: none;
						}						
					}
					a {
						display: block;
						font-family: $font-family;
						font-size: 1.7rem;
						padding: 0;
						width: 100%;
						padding: 1rem 2rem;
						&.active {
							background: $bg-gold;
							border-bottom: none;
						}
					}
				}
				.tab-content {
					.tab-pane {
						padding: 4rem 2rem;
					}
				}
			}
		}
	}
}

// MOBILE SELECT - DASHBOARD
.dropdown-container {
	position: relative;
	.dropdown-button {
		display: none;
	}
	.dropdown-content {
		a {
			margin-right: 2rem;
			&:last-of-type {
				margin-right: 0;
			}
		}
	}
	@include media-breakpoint-between(xs,md) {
		.dropdown-button {
			display: block;
			display: block;
			-webkit-appearance: none;
	        -moz-appearance: none;
	        appearance: none;
	        min-height: 55px;
	        font-weight: 400;
	        font-size: 1.7rem;
	        width: 100%;
	        padding: 0.5rem 2rem;
	        display: block;
	        position: relative;
	        text-align: left;
	        z-index: 25;
	        background: url('../assets/images/down-arrow.png') no-repeat;
	        background-position: right 18px center;
	        box-shadow: none  !important;
	        background-color: white;
	        border: 1px solid $font-clr;
	        &:before {
				content: attr(navigation-title);
		    }
		}
		.dropdown-content {
			display: none;
   			z-index: 10;
			position: absolute;
			padding: 1rem 2rem;
			border-left: 1px solid $font-clr;
			border-bottom: 1px solid $font-clr;
			border-right: 1px solid $font-clr;
			a {
				width: 100%;
				margin-right: 0;
				&:before {
					display: none;
				}
			}
		}

		&.open {
			background: white;
			.dropdown-button {
				background: url('../assets/images/up-arrow.png') no-repeat;
				background-position: right 18px center;
			}
			.dropdown-content {
				width: 100%;
				display: block;
				background: white;
			}
		}
	}
}


//ICON HEADER
.pb-icon-header {
	.cards {
		margin-top: 7rem;
	}
	@include media-breakpoint-only(md) {
		.card {
			text-align: center;
		}
		.card:not(:last-of-type) {
			margin-bottom: 3.5rem;
		}
		.card:nth-of-type(3) {
			margin-bottom: 0;
		}
	}
	@include media-breakpoint-between(xs,sm) {
		.cards {
			margin-top: 4rem;
			text-align: center;
		}
	}
}

//TABLES
table {
    width: 100%;
    margin-top: 5rem;
    thead {
        th {
            font-family: $heading;
            font-size: 1.9rem;
            font-weight: 500;
            padding: 0 1.5rem 2rem 1.5rem;
        }
    }
    tbody {
        tr {
            border-top: 1px solid;
            border-color: rgba($font-clr, 0.3);
            &:nth-child(odd) {
				background-color: transparent;
			}
			&:last-of-type {
				border-bottom: 1px solid rgba($font-clr, 0.3);
			}
        }
        td {
            font-size: 1.5rem;
            padding: 1.5rem;
            strong,
            &.strong {
            	font-weight: 500;
            }
        }
    }
    ul {
    	padding-left: 2rem;
	}
	.btn {
		margin-top: 0;
		float: right;
	}
	label {
		width: 100%;
	}
	select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 45px;
        font-weight: 300;
        font-size: 1.5rem;
        width: 100%;
        padding: 2px 10px;
        display: block;
        position: relative;
        z-index: 25;
        background: url('../assets/images/down-arrow.png') no-repeat;
        background-position: right 18px center;
        background-color: white;
        box-shadow: none  !important;
        border: 1px solid $font-clr;
        color: $font-clr;
        &:active,
        &:focus {
            background: url('../assets/images/up-arrow.png') no-repeat;
            background-position: right 18px center;
            background-color: white;
            border: 1px solid $font-clr !important;
            outline: none  !important;
            box-shadow: none  !important;
        }
        option {
            font-weight: 300;
            white-space: pre;
            padding-top: 1rem;
            min-height: 35px;
        }
        @include media-breakpoint-between(xs, sm) {
            min-width: auto;
        }
    }
}

.pb-table {
	table {
		width: 100%;
		margin-top: 5rem;
	}
	thead {
		th {
			@include fluid-type(1.8rem, 2.2rem);
			font-family: $heading;
			font-weight: 500;
			padding: 0 1.5rem 2rem 1.5rem;
		}
	}
	tbody {
		tr {
			border-top: 1px solid;
            border-color: $font-clr;
			&:nth-child(odd) {
				background-color: $bg-stone;
			}
			&:last-of-type {
				border-bottom: 1px solid $font-clr;
			}
		}
		td {
			font-size: 1.5rem;
			padding: 1.5rem;
		}
	}

	table {
		table-layout: fixed;
	}

	.table-cols {
		&--i2 {
			td {
				width: 50%;
			}
		}

		&--i3 {
			td {
				width: 33.333%;
			}
		}

		&--i4 {
			td {
				width: 25%;
			}
		}

		&--i5 {
			td {
				width: 20%;
			}
		}
	}

	&__content {
		p {
			font-size: 1.5rem;
		}
	}
}

.table-container {
	position: relative;
	@include media-breakpoint-down(sm) {
		.table:after {
		    content: "";
		    display: block;
		    height: 100%;
		    width: 7rem;
		    position: absolute;
		    right: 0;
		    top: 0;
		    background: linear-gradient(to left,rgba(255,255,255,.9) 20%,rgba(255,255,255,0) 100%);
		}

		.table:before {
			content: "";
			background: url('../assets/images/swipe.png') no-repeat center;
			background-size: 100%;
			height: 3rem;
		    width: 3rem;
		    position: absolute;
		    bottom: -0.7rem;
		    right: 1rem;
		    z-index: 1;
		}
	}
}

.table:not(.table--no-scroll) {
	&::-webkit-scrollbar {
	    background: #ececec;
	    border-radius: 10px;
	    height: 16px;
	}
	&::-webkit-scrollbar-thumb {
	   	background: $bg-gold;
	    border-radius: 26px;
	    border: 2px solid $bg-gold;
	}
	@include media-breakpoint-between(xs,sm) {	
		flex-wrap: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		padding-bottom: 2rem;
		table {
			min-width: 880px;
		}
	}
}


//ACCORDION
.pb-accordion {
	.accordion {
		margin-top: 5rem;
	}
	.accordion__item {
		position: relative;
		padding: 2rem 0;
		margin-bottom: 0;
		border-top: 1px solid $font-clr;
		&:last-of-type {
			border-bottom: 1px solid $font-clr;
		}
	}
	.accordion__title {
		@include fluid-type(2.2rem, 2.6rem);
		font-family: $heading;
        text-decoration: none;
        display: block;
        cursor: pointer;
		width: 100%;
        display: flex;
        align-items: center;
		&:focus {		
			outline: none;
	  		box-shadow: none;
		}
        &:after {
            content: "+";
            font-size: 26px;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 15px;
            right: 0;
            text-align: right;
        }
        &[aria-expanded="true"] {
            padding: 0 2rem 0rem 0;
            &:after {
                content: "-";
                font-size: 30px;
            }
        }
	}
    .accordion__content {
    	font-family: $font-family;
    	font-size: 1.5rem;
    	padding: 1rem 10rem 1rem 0;
    }
    @include media-breakpoint-between(xs,sm) {
    	.accordion__content {
	    	padding: 1rem 3rem 1rem 0;
	    }
    }

}

//POST LIST
.pb-posts {
	h2 {
		margin-bottom: 2.5rem;
	}
	a {
		&:not([class]) {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

//TIMELINE
.pb-timeline {
	margin-bottom: $pbPadding;

	h3 {
		margin-bottom: 0;
	}
	.row:not(.timeline) {
		margin-bottom: 3.5rem;
		align-items: flex-end;
	}

	@include media-breakpoint-between(xs,md) {
		margin-bottom: 0;
	}
}

.timeline {
	margin-top: 5rem;
	&__line {
		height: 1px;
		width: 100%;
		display: block;
		position: relative;
		margin-bottom: 3rem;

		&:before {
			content: "";
			height: 29px;
			width: 29px;
			display: block;
			border-radius: 50%;
			background: white;
			border: 2px solid $bg-gold;
			position: absolute;
			left: 0;
			top: -14px;
			opacity: 0;
			z-index: 2;
		}

		&:after {
			content: "";
			background-color: $bg-gold;
			height: 2px;
			position: absolute;
			left: 0;
			top: 0;
			width: 0;
			display: block;
			transition: width 1s ease-in-out;
		}
	}

	&__heading {
		min-height: 6.6rem;
		margin-bottom: 0;
	}

	&__top {
		opacity: 0;
	}

	&__bottom {
		padding-right: 2rem;
		opacity: 0;
		p {
			line-height: 1.6;
			margin-top: 1rem;
		}
		strong {
			margin-top: 1rem;
			font-size: 1.8rem;
			display: inline-block;
			font-weight: 500;
		}
	}

	&--animated {
		.timeline__line {
			transition-delay: inherit;
			&:before {
				animation-duration: 1s;
				animation-fill-mode: both;
				animation-duration: 0.75s;
				animation-name: bounceIn;
			}
			&:after {
				width: 100%;
			}
		}

		.timeline__top,
		.timeline__bottom {
			animation-duration: 1s;
			animation-fill-mode: both;
			animation-duration: 0.75s;
			animation-name: bounceIn;
		}
	}

	&__item {
		&:nth-of-type(2) {
			.timeline__line {
				&:before {
					animation-delay: 1s;
				}
				&:after {
					transition-delay: 1s;
				}
			}
			.timeline__top,
			.timeline__bottom {
				animation-delay: 1s;
			}
		}
		&:nth-of-type(3) {
			.timeline__line {
				&:before {
					animation-delay: 2s;
				}
				&:after {
					transition-delay: 2s;
				}
			}
			.timeline__top,
			.timeline__bottom {
				animation-delay: 2s;
			}
		}
		&:nth-of-type(4) {
			.timeline__line {
				&:before {
					animation-delay: 3s;
				}
				&:after {
					transition-delay: 3s;
				}
			}
			.timeline__top,
			.timeline__bottom {
				animation-delay: 3s;
			}
		}
		&:nth-of-type(5) {
			.timeline__line {
				&:before {
					animation-delay: 4s;
				}
				&:after {
					transition-delay: 4s;
				}
			}
			.timeline__top,
			.timeline__bottom {
				animation-delay: 4s;
			}
		}
		&:nth-of-type(6) {
			.timeline__line {
				&:before {
					animation-delay: 5s;
				}
				&:after {
					transition-delay: 5s;
				}
			}
			.timeline__top,
			.timeline__bottom {
				animation-delay: 5s;
			}
		}
	}

	&::-webkit-scrollbar {
	    background: #ececec;
	    border-radius: 10px;
	    height: 16px;
	}
	&::-webkit-scrollbar-thumb {
	   	background: $bg-gold;
	    border-radius: 26px;
	    border: 2px solid $bg-gold;
	}

	@media(max-width: 1140px) {
		flex-wrap: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		padding-bottom: 2rem;

		.col {
			min-width: 32%;
		}
	}

	@include media-breakpoint-between(xs,sm) {
		flex-wrap: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		padding-bottom: 2rem;

		.col {
			min-width: 75%;
		}
	}
}

//IMAGE/VIDEO SLIDER
.pb-discover-uni,
.pb-image-slider,
.pb-video-slider {
	h2 {
		margin-bottom: 3rem;

		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}
	}
}

//VIRTUAL TOUR
.pb-virtual-tour {
	h2 {
		margin-bottom: 3rem;
	}
}

//TESTIMONIAL SLIDER
.pb-testimonial-slider {
	h2 {
		margin-bottom: 5rem;
	}
	h4,
	h5 {
		line-height: 1.2;
	}
	.testimonial-slider {
		&__content {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		&__photo {
			display: block;
			width: 100px;
			height: 100px;
			border-radius: 100px;
			margin-bottom: 2rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&--alt {
		h2 {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-between(xs,sm) {
		h2 {
			margin-bottom: 2rem;
		}
		.testimonial-slider {
			&__image {
				margin-bottom: 2rem;
			}
		}
	}
}

//CONTACT FORM BLOCK
.pb-form {
	@include media-breakpoint-down(sm) {
		.clip--both--sm.form--block {
			clip-path: polygon(calc(100% - 60px) 0,100% 0px,100% 100%,60px 100%,0 calc(100% - 60px),0 0);
		}

		.frm_forms form {
			padding-top: 2rem;
		}
	}
}

//MARKETING BLOCK
.pb-hero + .pb-marketing-form.pb-eprints-form {
	margin-top: 0;

	+ .bg-stone {
		margin-top: 1.5rem !important;
	}

}

.pb-marketing-form {
	position: relative;
	overflow: hidden;

	+ .bg-stone {
		margin-top: 1.5rem !important;
	}

	&.pb-eprints-form.pb {
		padding-top: 0.5*$pbPadding;
		padding-bottom: 0.5*$pbPadding;

		@include media-breakpoint-down(md) {
			padding-top: 0.75*$pbPadding-tablet;
			padding-bottom: 0.75*$pbPadding-tablet;
		}

		@include media-breakpoint-between(xs,sm) {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}

	}

	h2 {
	    margin-bottom: 4rem;
	}

	.pb-block-header p {
		text-align: center;

		&:first-child {
			margin-top: -1rem;
		}

		&:last-child {
			margin-bottom: 4rem;
		}
	}

	/* Portrait */
	@media only screen 
	  and (min-device-width: 768px) 
	  and (max-device-width: 1024px) 
	  and (orientation: portrait) 
	  and (-webkit-min-device-pixel-ratio: 1) {
		h2 {
		    font-size: 4rem;
		}
	}
}

.pb-instagram {
	.instagram-widget {
		margin-top: 2.5rem;
	}
	.sbi_photo_wrap {
		padding-bottom: 2rem;
	}
}

//HOMEPAGE BLOCKS
.coloured-block-links {
	&__link {
		&:nth-of-type(1) {
			background-color: $bg-gold;
		}
		&:nth-of-type(2) {
			background-color: #FACB75;
		}
		&:nth-of-type(3) {
			background-color: #FBD897;
		}
		&:nth-of-type(4) {
			background-color: #FDE5BA;
		}
		&:nth-of-type(5) {
			background-color: $bg-blue;
		}
	}

	@include media-breakpoint-between(xs,md) {
		&__link {
			margin-bottom: 0;
			.link {
				margin-top: 1rem;
			}
			.card__inner {
			    padding: 3rem 2rem 2rem 2rem;
			}
		}
	}

	@include media-breakpoint-between(xs,sm) {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
		&--alumni {
			.container {
			    padding-left: 15px;
			    padding-right: 15px;
			}
		}
	}
}

//ANIMATION
@keyframes bounceIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}



// EPrints

.pb-eprints-journal-archive {

	.pb-block-header {
		margin-bottom: 8rem;

		@include media-breakpoint-down(md) {
			margin-bottom: 4rem;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 4rem;

		@include media-breakpoint-down(md) {
			grid-template-columns: repeat(1, 1fr);
		}

	}

	&__item {
		display: flex;
		align-items: stretch;
		flex-direction: column;
		justify-content: stretch;

		&.hidden {
			display: none !important;
		}

		padding: 4rem 3rem;

		+ .pb-eprints-journal-archive__item {
			// margin-top: 4rem;
		}

	}

	&__heading {

		a {
			text-decoration: none !important;
		}

	}

	&__meta {
		display: block;
		margin-top: 1.5rem;
		font-size: 1.4rem;
		font-style: italic;
	}

	&__authors {
		display: inline-block;

		ul {
			display: inline-block;
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				font-size: 1.4rem;

				// author ORCID in eprints archive grid
				a[href^="https://orcid.org"],
				a[href^="http://orcid.org"] {
					position: relative;
					padding-right: 3rem;
					&:after {
						content: '';
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						background-image: url('../assets/images/icon-orcid.svg');
						background-size: contain;
						background-repeat: no-repeat;
						width: 22px;
						height: 22px;
					}
				}

				+ li:before {
					content: '|';
					display: inline-block;
					margin: 0 1rem 0 0.5rem;
				}
			}
		}

	}

	&__separator {
		display: inline-block;
		margin: 0 1rem;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: #3a3131;
		transform: translateY(-3px);
	}

	&__date {
		display: inline-block;
	}

	&__abstract {
		margin-top: 1.5rem;

		.eprints-read-more-link {
			color: #3a3131;
			font-weight: 600;

			&:hover {
				color: #3a3131;
				text-decoration: underline;
			}
		}
	}

	&__links {
		padding-top: 1.5rem;
		margin-top: auto;
		margin-bottom: -2rem;

		a.link {
			margin-right: 3.5rem;
			margin-bottom: 2rem;

			&.bg-white {
				background-color: $bg-white;
				&:hover {
					color: #fff;
    				background-color: #3a3131;
				}
			}

			&.bg-gold {
				background-color: $bg-gold;
				&:hover {
					color: #fff;
    				background-color: #3a3131;
				}
			}

			&.bg-blue {
				background-color: $bg-blue;
				&:hover {
					color: #fff;
    				background-color: #3a3131;
				}
			}

			&.bg-green {
				background-color: $bg-green;
				&:hover {
					color: #fff;
    				background-color: #3a3131;
				}
			}

			&.bg-purple {
				background-color: $bg-purple;
				&:hover {
					color: #fff;
    				background-color: #3a3131;
				}
			}

			&.bg-orange {
				background-color: $bg-orange;
				&:hover {
					color: #fff;
    				background-color: #3a3131;
				}
			}

			&.bg-stone {
				background-color: $bg-stone;
				&:hover {
					color: #fff;
    				background-color: #3a3131;
				}
			}

			&.bg-white,
			&.bg-gold,
			&.bg-blue,
			&.bg-green,
			&.bg-purple,
			&.bg-orange,
			&.bg-stone {
				padding: 1.5rem 3.5rem;
				text-decoration: none;

				@include media-breakpoint-between(xs,md) {
					padding: 1.5rem 2.5rem;
				}

				&:hover {
					text-decoration: none;

					&:before {
						content: url(../assets/images/arrow-icon-white.svg);
					}
				}

			}

		}

	}

	&__load-more {
		display: table;
		margin: 4rem auto 0 !important;
	}

}



.pb-hero {

	&__eprints-meta {
		display: block;
		margin-top: 1.5rem;
		font-size: 1.4rem;
		font-style: italic;
	}

	&__eprints-authors {
		display: inline-block;

		ul {
			display: inline-block;
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				font-size: 1.4rem;

				+ li:before {
					content: '|';
					display: inline-block;
					margin: 0 1rem 0 0.5rem;
				}

			}
		}

	}

	&__eprints-separator {
		display: inline-block;
		margin: 0 1rem;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: #3a3131;
		transform: translateY(-3px);
	}

	&__eprints-date {
		display: inline-block;
	}

	&__eprints-citation {
		margin-top: 1.5rem;
	}

	&__eprints-journal_title {
		margin-top: 1.5rem;
	}

}

// new testimonial module
.pb-testimonial-module {

	&.pb {
		margin: 0 !important;
		padding-top: 4rem;
		padding-bottom: 4rem;

		& + .pb.bg-white {
			padding-top: 8rem;
		}

	}

	.row {
		justify-content: center;
	}

	&__content {
		max-width: 900px;
		margin: 0 auto;
		padding: 10rem;
		clip-path: polygon( calc(100% - 60px) 0, 100% 60px, 100% 100%, 60px 100%, 0 calc(100% - 60px),0 0);

		@include media-breakpoint-down(md) {
			padding: 6rem;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			max-width: calc(100% - 30px);
			padding: 4rem;
		}

		blockquote {
			@extend h3;

			&:before,
			&:after {
				content: '"';
			}

		}

		cite {
			display: block;
			margin-top: 1.5rem;
			font-style: normal;
			font-size: 1.8rem;
		}

	}

	&__button {
		@extend .btn;

		background: transparent;
		padding-left: 2rem;
		padding-right: 2rem;
		margin-top: 1.5rem;

	}

}

.norland-translation-toggle {
	position: fixed;
	left: auto;
	right: 4rem;
	bottom: 0;
	z-index: 99;
	background-color: $bg-gold;
	padding: 2rem;

	@include media-breakpoint-down(md) {
		right: 0;
		padding: 1rem;
	}

	&__heading {
		font-size: 1.7rem;
		font-weight: bold;

		@include media-breakpoint-down(md) {
			font-size: 1.3rem;
		}
	}

	.gtranslate_wrapper {
		margin-top: 1.5rem;

		@include media-breakpoint-down(md) {
			margin-top: 0.75rem;

			.gt_switcher {
				width: 137px !important;

				a {
					width: 125px !important;
					font-size: 1.2rem;
				}

			}

		}
	}

}


.eprints-popup-trigger {
	position: fixed;
	left: 2rem;
	bottom: 2rem;
	z-index: 99;
}

.eprints-popup {

	.close {
		font-size: 2rem;
	}

	.modal-dialog {
		max-width: 768px;
	}

	.modal-header {
		padding: 1rem 2rem;
		border-bottom: none;
	}

	.modal-body {
		padding: 0 2rem 2rem 2rem;
	}

	&__heading {
		text-align: center;
	}

	&__content {
		text-align: center;
		margin: 2rem 0 2.5rem;
	}

	&__form {

	}

}