//******************************************************************************
// TYPOGRAPHY
//******************************************************************************


// Headings
// -----------------------------------------------------------------------------
// Define a reusable & flexible class for each heading, which can be used
// everywhere in the page to replicate the style of the headings while keeping
// a clean structure.
// http://csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css/

h1,h2,h3,h4,h5,h6, .alpha, .beta, .gamma, .delta, .epsilon, .zeta {
	font-family: $heading;
	font-weight: 400;
}

h1, .alpha {
	@include fluid-type(2.9rem, 4.3rem);
	line-height: 1;
}

h2, .beta {
	@include fluid-type(2.5rem, 4rem);
	line-height: 1.2;
}

h3, .gamma {
	@include fluid-type(2.5rem, 2.9rem);
	line-height: 1.2;
}

h4, .delta {
	@include fluid-type(2.2rem, 2.6rem);
	line-height: 1.2;
}

h5, .epsilon {
	@include fluid-type(1.8rem, 2.2rem);
	line-height: 1.2;
}

h6, .zeta {

}

.pb-generic-text__content {
	ul {
		margin-top: 2rem!important;
	}
}
