//******************************************************************************
// SLIDER
// Carousel styles
//******************************************************************************
.slick-slider {
	position: relative;
	opacity: 0;
	transition: opacity .2s ease-in;
	.video-container,
	.objectfit {
		height: 500px;
	}

	&.slick-initialized {
		opacity: 1;
	}

	.slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        > div,
        .card {
            height: 100%;
        }
    }

	.slider__control {
		position: absolute;
		top: 20px;
		right: 15px;
		z-index: 1;
	}

	.slick-prev,
	.slick-next {
		font-size: 0;
		border: 0;
		width: 20px;
		height: 20px;
		display: inline-flex;
	    justify-content: center;
	    align-items: center;
		background-color: transparent;
		z-index: 5;
		padding: 0;
		&:focus {		
			outline: none;
	  		box-shadow: none;
		}
		&:before {
			position: absolute;
	    	content: '';
	    	width: 0;
			height: 0;
			border-style: solid;
		}
		&:hover {
			cursor: pointer;
		}
	}
	.slick-prev {
		&:before {
			border-width: 7px 8px 7px 0px;
			border-color: transparent $font-clr transparent transparent;
			left: 0;
		}
		&:hover {
			&:before {
				border-color: transparent $bg-gold transparent transparent;
			}
		}
	}
	.slick-next {
		&:before {
			border-width: 7px 0 7px 8px;
			border-color: transparent transparent transparent $font-clr;
			right: 0;
		}
		&:hover {
			&:before {
				border-color: transparent transparent transparent $bg-gold;
			}
		}
	}
	@include media-breakpoint-only(sm) {
		.video-container,
		.objectfit {
			height: 350px;
		}
	}

	@include media-breakpoint-only(xs) {
		.video-container,
		.objectfit {
			height: 250px;
		}
	}

	@include media-breakpoint-between(xs,sm) {
		.slider__control {
			top: 0;
		}
		.card {
			margin-bottom: 0;
		}
		.slick-prev {
			&:before {
				left: 10px;
			}
		}
	}
}

@supports (-ms-ime-align: auto) {
	.pb-image-slider {
		.slick-slider {
			.slider__control {
				right: 35px;
			}
			.slick-prev {
				&:before {
					border-color: transparent $bg-gold transparent transparent;
				}
			}
			.slick-next {
				&:before {
					border-color: transparent transparent transparent $bg-gold;
				}
			}
		}
	}
}

html[data-useragent*="rv:11.0"] {
	.pb-image-slider {
		.slick-slider {
			.slider__control {
				right: 35px;
			}
			.slick-prev {
				&:before {
					border-color: transparent $bg-gold transparent transparent;
				}
			}
			.slick-next {
				&:before {
					border-color: transparent transparent transparent $bg-gold;
				}
			}
		}
	}
}

//SITEWIDE - SLICK
.cards-slider,
.feature-skills-slider,
.testimonial-slider-alt {
	.slick-prev,
	.slick-next {		
		position: absolute;
		top: -1rem;
	}
	.slick-prev {
		left: 48.5%;
	}
	.slick-next {
		right: 48.5%;
	}

	@include media-breakpoint-only(md) {
		.slick-prev,
		.slick-next {		
			position: absolute;
		}
		.slick-prev {
			left: 47%;
		}
		.slick-next {
			right: 47%;
		}
	}

	@include media-breakpoint-between(xs,sm) {
		.slick-prev,
		.slick-next {		
			position: absolute;
			top: -5rem;
		}
		.slick-prev {
			left: 1rem; //was 45%
		}
		.slick-next {
			right: 45%;
			left: 3.5rem;
		}
	}
}

@include media-breakpoint-between(xs,sm) {
	.pb-cards-block {
		.cards-slider .slick-next, .cards-slider .slick-prev {
			top: -2rem;
		}
	}
}

@include media-breakpoint-down(md) {
	.feature-skills-slider,
	.testimonial-slider-alt {
		.slick-prev,
		.slick-next {
			top: -7rem;
		}
	}
}
@include media-breakpoint-down(sm) {
	.feature-skills-slider,
	.testimonial-slider-alt {
		.slick-prev,
		.slick-next {
			top: -5rem;
		}
	}
}
.testimonial-slider-alt{
	.slick-prev,
	.slick-next {		
		position: absolute;
		top: -7rem;
	}
	@include media-breakpoint-down(sm) {
		.slick-prev {
			left: 43%;
		}
		.slick-next {
			left: auto;
			right: 45%;
		}
	}
}

.cards-slider,
.feature-skills-slider {
	.slick-list {
	    margin-right: -15px;
	    margin-left: -15px;
	}

	&__item {
		padding-right: 15px;
    	padding-left: 15px;
	}
}

//SLICK IMAGE/VIDEO
.image-slider {
	margin-left: 0;
	margin-right: 0;
	&__content {
		margin-top: 12rem;
	}
	@include media-breakpoint-only(md) {
		&__content {
			margin-top: 3rem;
		}
	}
	@include media-breakpoint-between(xs,sm) { 
		&__content {
			margin-top: 2rem;
		}
		&__image {
			// padding-left: 0;
			// padding-right: 0;
		}
	}
}

//SLICK IMAGE/VIDEO
.testimonial-slider {
	.objectfit {
		height: unset;
	}
	.slider-arrows {
		left: -8px;
    	position: relative;
    	margin-top: 1.5rem;
	}
	.slick-prev,
	.slick-next {
		width: 30px;
		height: 30px;
	}
	.slick-prev {
		&:before {
			left: auto;
		}
	}
	.slick-next {
		&:before {
			right: auto;
		}
	}
}

.pb-hero--slider {
	&__controls {
		position: relative;

		.col-md-12 {
			min-height: 0;
			.slider-arrows {
				position: absolute;
				right: 15px;
				bottom: 0;
				background-color: $bg-stone;
				padding: 6px 4px;

				button {
					appearance: none;
					border: none;
					background-color: transparent;
					padding: 4px 8px;
				}
			}
		}
	}
}

.hide-arrows {
	.slick-prev,
	.slick-next {
		display: none;
	}
}