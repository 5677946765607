/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 14, 2020 */

@font-face {
    font-family: 'klinic_slab';
    src: url('../assets/fonts/klinicslabmediumit-webfont.woff2') format('woff2'),
         url('../assets/fonts/klinicslabmediumit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'klinic_slab';
    src: url('../assets/fonts/klinicslabmedium-webfont.woff2') format('woff2'),
         url('../assets/fonts/klinicslabmedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'klinic_slablight_italic';
//     src: url('klinicslablightit-webfont.woff2') format('woff2'),
//          url('klinicslablightit-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'klinic_slablight';
//     src: url('klinicslablight-webfont.woff2') format('woff2'),
//          url('klinicslablight-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'klinic_slabbook_italic';
//     src: url('klinicslabbookit-webfont.woff2') format('woff2'),
//          url('klinicslabbookit-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'klinic_slabbook';
//     src: url('klinicslabbook-webfont.woff2') format('woff2'),
//          url('klinicslabbook-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'klinic_slabbold_italic';
//     src: url('klinicslabboldit-webfont.woff2') format('woff2'),
//          url('klinicslabboldit-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'klinic_slabbold';
//     src: url('klinicslabbold-webfont.woff2') format('woff2'),
//          url('klinicslabbold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }



// added 29th July 2024

// Normal

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-black-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-extrabold-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-bold-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-semibold-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-extralight-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-extralight-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-thin-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


// Italic

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-bolditalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-semibolditalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-mediumitalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-italic-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-italic-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-lightitalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-extralightitalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-extralightitalic-webfont.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/poppins-thinitalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/poppins-thinitalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}