//******************************************************************************
// STRUCTURE
// General elements of the structure, including containers and wrappers
//******************************************************************************

// Content
// -----------------------------------------------------------------------------
.document {

}

.content {
    position: relative;
}

// Discover uni widget
// -----------------------------------------------------------------------------

.kis-widget {
	justify-content: center;
}


// Search bar
// -----------------------------------------------------------------------------
.home {
	.row--5-cols {
		.col-20-percent {
			max-width: 20%;
			flex-basis: 20%;
		}

		@include media-breakpoint-only(md) {
			.col-20-percent {
				max-width: 50%;
				flex-basis: 50%;
		    	&:last-of-type {
		    		width: 100%;
		    		flex-basis: unset;
		    		max-width: unset;
		    	}
		    }
		}

		@include media-breakpoint-between(xs,sm) {
			.col-20-percent {
				width: 100%;
		    	flex-basis: unset;
		    	max-width: unset;
		    }
		}

		@media only screen 
		  and (min-device-width: 768px) 
		  and (max-device-width: 1024px) 
		  and (-webkit-min-device-pixel-ratio: 1) {
			.col-20-percent:nth-of-type(5) {
				min-height: 15.4rem;
			}
		}
	}
}

.page-template-template-dashboard{
	.row--5-cols {
		&::-webkit-scrollbar {
		    background: #ececec;
		    border-radius: 10px;
		    height: 16px;
		}
		&::-webkit-scrollbar-thumb {
		   	background: $bg-gold;
		    border-radius: 26px;
		    border: 2px solid $bg-gold;
		}
		.col-20-percent {
			max-width: 20%;
			flex-basis: 20%;
		}

		@include media-breakpoint-between(xs,md) {
			flex-wrap: nowrap;
			overflow-x: scroll;
			overflow-y: hidden;
			padding-bottom: 2rem;
			.col-20-percent {
				max-width: unset;
				flex-basis: unset;
				min-width: 270px;
			}
		}
	}
}

.p-t-0 {
	padding-top: 0 !important;
}
.p-t-30 {
	padding-top: 3rem !important;
}
.p-t-50 {
	padding-top: 5rem !important;
}
.m-b-0 {
	margin-bottom: 0 !important;
}
.m-t-30 {
	margin-top: 3rem !important;
}
.m-t-40 {
	margin-top: 4rem !important;
}
.m-r-30 {
	margin-right: 3rem !important;
}
.m-b-10 {
	margin-bottom: 1rem !important;
}
.m-b-20 {
	margin-bottom: 2rem !important;
}
.m-b-30 {
	margin-bottom: 3rem !important;
}
.m-b-50 {
	margin-bottom: 5rem !important;
}
.m-l-5 {
	margin-left: 5px !important;
}

.pb-large-cta .row.flex-row-reverse {
	justify-content: flex-end;
}

@media (min-width: 1024px) {
	.pb-generic-text .row.flex-row-reverse {
		.offset-lg-1 {
			margin-left: 0;
		    margin-right: 8.33333%;
		}
	}
}

#mceu_67 {
	display: none;
}

/* Landscape tablet masonry re-paint hack */
// @media only screen 
//   and (min-device-width: 768px) 
//   and (max-device-width: 1024px) 
//   and (orientation: landscape) 
//   and (-webkit-min-device-pixel-ratio: 1) {
// 		.container {
// 			max-width: 722px;
// 		}
// }