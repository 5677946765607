//******************************************************************************
// FOOTER
//******************************************************************************

.sitewide-popup {

	.modal-dialog {
		max-width: 420px;
	}

	.modal-content {
		position: relative;
		border: none;
		border-radius: 0px;
		clip-path: polygon(100% 0, 100% 100%, 30% 100%, 0 70%, 0 0);
		aspect-ratio: 1.25 / 1;
		background-color: #FBCB76;

		@media screen and (max-width: 959.98px) {

		}
	}

	.modal-header {
		border: none;
		border-radius: 0px;
		padding: 2rem;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		z-index: 3;
	}

	.close {

	}

	.modal-body {
		display: block;
		padding: 3rem;
	}

	&__left {
		width: 100%;
	}

	&__right {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-top: -2rem;

		@include media-breakpoint-down(xs) {
			margin-top: 0;
		}

	}

	&__heading {

	}

	&__link {
		font-size: 1.7rem;
		margin-top: 3rem;
		font-family: inherit;
		font-weight: 500;
		padding-right: 125px;

		@include media-breakpoint-down(xs) {
			padding-right: 0;
		}

		a {
			color: inherit;
		}
	}

	&__image {
		max-width: 105px;

		@include media-breakpoint-down(xs) {
			display: inline-block;
			margin: 4rem 0 0 auto;
		}


		img {
			display: block;
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 200px;
		}

	}

}

.site-award-logos {

	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #f0e8e1;
		margin-top: -3rem;
		padding-top: 3rem;

		@media screen and (max-width: 1123.98px) {
			flex-wrap: wrap;
		}

		@include media-breakpoint-down(md) {
			justify-content: center;
		}

	}

	&__item {
		display: block;
		width: 100%;
		max-width: 22.5%;

		@media screen and (max-width: 1123.98px) {
			max-width: 46%;
		}

		@include media-breakpoint-down(md) {
			max-width: 300px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			object-fit: contain;
			object-position: center;
		}

	}

}

.site-footer {
	.menu {
		padding: 0;
		margin: 0;
		list-style: none;
		.menu-item {
			display: inline-block;
		}
	}
	a {
		color: inherit;
	}
	&__top {
		margin-top: 9rem;
		.menu {
			padding-top: 2rem;
			padding-bottom: 2rem;
			border-top: 1px solid $bg-stone;
			border-bottom: 1px solid $bg-stone;
			display: flex;
			justify-content: space-between;
			font-size: 1.9rem;
			font-weight: 400;
		}
	}
	&__bottom {
		padding-top: 3rem;
		padding-bottom: 9rem;
	}
	&__copyright {
		p {
			font-size: 1.3rem;
			margin-top: 0;
			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.menu {
			margin-top: 1rem;
			font-size: 1.3rem;
			a {
				margin-right: 1rem;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	&__social-container {
		.social-links {
			justify-content: flex-end;
		}
	}

	@include media-breakpoint-only(md) {
		&__top {
			.menu {
	    		font-size: 1.4rem;
	    	}
	    }
	}

	@include media-breakpoint-between(xs, sm) {
		&__top {
			margin-top: 5rem;
			.menu {
			    padding-top: 3rem;
    			padding-bottom: 2rem;
				display: block;
	    		justify-content: unset;
	    		font-size: 1.5rem;
	    		.menu-item {
				    margin-right: 3rem;
				    margin-bottom: 1rem;
				}
	    	}
	    }
	    &__bottom {
			padding-top: 3rem;
		}
	    &__social-container {
			.social-links {
				margin-top: 5rem;
				justify-content: flex-start;
			}
		}
	}
}


.social-links {
	margin: 0;
	padding: 0;
	display: flex;
	&__li {
		background-color: $bg-brown;
		display: inline-block;
		margin-left: 5px;
		width: 34px;
		height: 34px;
		&:hover {
			background-color: $bg-gold;
		}

		&:first-of-type {
			margin-left: 0;
		}
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	@include media-breakpoint-only(md) {
		&__li {
			width: 24px;
			height: 24px;
		}
	}
}