/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "../../.npm/node_modules/bootstrap/scss/functions";
@import "../../.npm/node_modules/bootstrap/scss/variables";
@import "bootstrap_overrides/_variables";
@import "../../.npm/node_modules/bootstrap/scss/mixins";
@import "bootstrap_overrides/_mixins";
@import "../../.npm/node_modules/bootstrap/scss/root";
@import "../../.npm/node_modules/bootstrap/scss/reboot";
@import "../../.npm/node_modules/bootstrap/scss/images";
@import "../../.npm/node_modules/bootstrap/scss/grid";
@import "../../.npm/node_modules/bootstrap/scss/forms";
@import "../../.npm/node_modules/bootstrap/scss/transitions";
@import "../../.npm/node_modules/bootstrap/scss/dropdown";
@import "../../.npm/node_modules/bootstrap/scss/nav";
@import "../../.npm/node_modules/bootstrap/scss/navbar";
@import "../../.npm/node_modules/bootstrap/scss/modal";
@import "../../.npm/node_modules/bootstrap/scss/close";
@import "../../.npm/node_modules/bootstrap/scss/utilities";