//******************************************************************************
// BRAND & SKINS
// colors, specific typography, logos
//******************************************************************************

$bg-white: #fff;
$bg-gold: #FABF54;
$bg-gold-tint: #FCDBA0;
$bg-blue: #80BACE;
$bg-blue-tint: #9AC8D8;
$bg-green: #6CBFA2;
$bg-green-tint: #9ECDBB;
$bg-purple: #B8A5D5;
$bg-purple-tint: #C8BBDB;
$bg-orange: #F29563;
$bg-orange-tint: #F8C0A3;
$bg-stone: #F0E8E1;

$bg-brown: #684D3B;

:root {
	--bg-white: $bg-white;
	--bg-gold: $bg-gold;
	--bg-gold-tint: $bg-gold-tint;
	--bg-blue: $bg-blue;
	--bg-blue-tint: $bg-blue-tint;
	--bg-green: $bg-green;
	--bg-green-tint: $bg-green-tint;
	--bg-purple: $bg-purple;
	--bg-purple-tint: $bg-purple-tint;
	--bg-orange: $bg-orange;
	--bg-orange-tint: $bg-orange-tint;
	--bg-stone: $bg-stone;
}

.bg-white {
	background-color: $bg-white;
}
.bg-gold {
	background-color: $bg-gold;
}
.bg-gold-tint {
	background-color: $bg-gold-tint;
}
.bg-blue {
	background-color: $bg-blue;
}
.bg-blue-tint {
	background-color: $bg-blue-tint;
}
.bg-green {
	background-color: $bg-green;
}
.bg-green-tint {
	background-color: $bg-green-tint;
}
.bg-purple {
	background-color: $bg-purple;
}
.bg-purple-tint {
	background-color: $bg-purple-tint;
}
.bg-orange {
	background-color: $bg-orange;
}
.bg-orange-tint {
	background-color: $bg-orange-tint;
}
.bg-stone {
	background-color: $bg-stone;
}