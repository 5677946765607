//******************************************************************************
// HEADER
//******************************************************************************
$search-button-width: 46px;
$menu-height: 16rem;

@include media-breakpoint-down(md) {
	.site-header.open .header-sitewide-cta {
		margin-top: 0;
	}
}

.header-sitewide-cta {
	padding: 2rem 0;
	margin-bottom: -3.6rem;
	margin-top: 3.6rem;

	@include media-breakpoint-down(md) {
		padding: 1.5rem 0;
		margin-bottom: 0;
		margin-top: 0;
	}

	.row {
		position: relative;
	}

	&__col {

	}

	&__flex {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;

		@include media-breakpoint-down(md) {
			display: block;
			padding-right: 4rem;
		}

	}

	&__heading {
		max-width: 60%;
		margin-left: 0;
		margin-right: auto;

		@include media-breakpoint-down(md) {
			max-width: 100%;
			margin-bottom: 1.5rem;
		}

	}

	&__link {
		max-width: 40%;
		font-family: inherit;
		font-weight: 500;
		color: inherit;
		font-size: 1.6rem;

		@include media-breakpoint-down(md) {
			max-width: 100%;
			font-size: 1.4rem;
		}

	}

	&__close {
		margin-left: 4rem;

		@include media-breakpoint-down(md) {
			position: absolute;
			top: 0;
			right: 0;
		}

	}

}

.sticky-sitewide-cta {
	position: fixed;
	z-index: 501;
	right: 0;
	top: 50vh;
	background-color: white;
	padding: 2rem;
	width: 220px;
	border: 1px solid $bg-stone;
	h3 {
		font-size: 2.6rem;
		margin-bottom: 3rem;
	}
	
	&__mobile {
		display: none;
		font-size: 13px;
		color: inherit;
		font-weight: 500;
		&:hover {
			color: inherit;
		}

		@media(max-width: 350px) {
			font-size: 11px;
		}
	}
	&__close {
		position: absolute;
		top: 25px;
		right: 5px;
		border: none;
		background-color: transparent;
	}

	&--closed {
		display: none;
	}

	@media only screen and (max-width: 1400px) {
		padding: 1rem 2rem 1rem 4rem;
		width: 70%;
		right: auto;
		top: auto;
		bottom: 0;
		&__close {
			top: 10px;
			right: auto;
			left: 10px;
		}
		&__mobile {
			display: block;
		}

		&__desktop {
			display: none;
		}

	}
}

.site-header {
	width: 100%;
	background-color: #fff;
	position: fixed;
	z-index: 502;
	top: 0;
	transition: all .3s ease-in-out;
	padding-bottom: 3.6rem;

	&--hidden {
		top: -$menu-height;
		transition: all .3s ease-in-out;

		@include media-breakpoint-down(md) {
			@supports(scroll-behavior: smooth) {
				top: 0 !important;
			}
		}

		&.open {
			top: 0 !important;
		}
	}
	
	&__search-form {
		position: relative;
		height: $search-button-width;

		.search-form {
			position: absolute;
			overflow: hidden;
			right: $search-button-width;
			height: $search-button-width;
			width: 0;
			background-color: $bg-stone;
			transition: all .4s ease-in;

			input {
				padding: 3px 15px;
				font-weight: 400;
            	font-size: 16px;
				height: inherit;
				border-top: 1px solid $bg-stone;
                border-left: 1px solid $bg-stone;
                border-bottom: 1px solid $bg-stone;
                border-right: none;
                width: 100%;
                margin-right: 0;
				&:active,
	            &:focus {
	                outline: none  !important;
	                box-shadow: none  !important;
	                border: 1px solid $font-clr !important;
	            }
			}
		}

		.search-form-toggle {
			position: relative;
			z-index: 2;
			width: $search-button-width;
			height: $search-button-width;
			border: 1px solid $bg-stone;
			background-color: transparent;
			border-radius: none;
			appearance: none;
			&:active,
            &:focus {
                outline: none  !important;
                box-shadow: none  !important;
                border: 1px solid $font-clr !important;
            }
		}

		&--open {
			.search-form {
				right: $search-button-width;
				width: 642px;
			}
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.logo-container {
			a {
    			display: flex;
				align-items: center;
				height: 100%;
			}
		}
	}

	@include media-breakpoint-between(xs, sm) {
		background-color: transparent;
	}

	@include media-breakpoint-down(md) {
		position: relative;
		padding-bottom: 0;

		&__search-form {
			position: relative;
			background-color: white;
			.search-form {
				position: relative;
				right: 0;
				width: 100%;
				input {
					border-top: 1px solid white;
	                border-left: 1px solid white;
	                border-bottom: 1px solid white;
				}
			}

			.search-form-toggle {
				position: absolute;
				top: 0;
				right: 0;
				border: 1px solid white;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		&--alumni {
			padding-top: 3rem;

			.site-header__navs {
				display: block;
			}
		}
	}

	@include media-breakpoint-down(md) {
		&--alumni-stickyclosed {
			padding-top: 0rem;
		}
	}
}