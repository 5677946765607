//******************************************************************************
// FORMS & ERRORS
//******************************************************************************

// ALL FORMS
form {
    position: relative;

    p {
        font-size: 1.5rem;
    }

    // Signature 
    .sigWrapper {
        border: 0;
    }
    .sigPad .clearButton {
        position: relative;
        top: -25px;
        margin: 0 5%;
    }
    .sigPad .clearButton a {
        font-size: 1.5rem;
    }
    .sigPad .clearButton a,
    a {
        color: inherit;
        font-weight: 500;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }

    // All Forms
    .frm_hidden,
    .frm_screen_reader {
        font-size: 0 !important;
        margin: 0;
        display: block;
    }

    .hs-fieldtype-text > label,
    .frm_primary_label {
        font-size: 1.5rem;
        position: absolute;
        top: 1.7rem;
        left: 2.5rem;
        z-index: 75;
        transition: all .3s ease;
    }

    .hs-fieldtype-text.form-field-active > label {
            font-size: 1rem;
            top: 0.3rem;
            left: 2.6rem;
            opacity: .8;
    }

    .form-field-active {
        > .frm_primary_label {
            font-size: 1rem;
            top: 0.7rem;
            left: 2.6rem;
            opacity: .8;
        }
    }

    .fixed-label-active {
        .frm_primary_label {
            top: 0;
            left: 0;
            position: relative;
            font-weight: 400;
            font-size: 1.5rem;
        }
    }

    .frm_fields_container,
    .frm_combo_inputs_container {
        font-size: 0;
        margin-left: -1rem;
        margin-right: -1rem;

        .frm_html_container,
        .frm_repeat_buttons {
            font-size: 1.5rem;
        }
    }

    .frm_repeat_buttons {
        .frm_button {
            @extend .link;
            @extend .link--plus;

            &.frm_remove_form_row {
                margin-left: 3rem;

                @extend .link--minus;
            }
        }
    }

    .frm_first_repeat {
        .frm_button {
            &.frm_remove_form_row {
                display: none;
            }
        }
    }

    // HubSpot specific
    &.hs-form {
        ul {
            position: relative;
            list-style: none;
            margin: 0;
            padding: 0;
        }
        .hs-form-booleancheckbox-display {
            > span {
                margin-left: 0!important;
            }
        }
        .hs-fieldtype-checkbox {
            > label {
                font-weight: 400;
                font-size: 1.5rem;
            }
        }

        fieldset {
            max-width: 100% !important;

            &[class^="form-columns-"] {
                margin-left: 1rem;
                margin-right: 1rem;
            }

            .hs-input {
                width: 100% !important;
            }
        }
    }

    // All forms
    .hs-form-field,
    .frm_form_field,
    .sf-field-search,
    .acf-field {
        margin-bottom: 2.5rem;
        position: relative;
        padding: 0 10px;

        input[type="text"],
        input[type="number"],
        input[type="password"],
        input[type="email"],
        input[type="tel"],
        textarea {
            padding: 2rem 1.5rem 1rem;
            display: block;
            font-weight: 300;
            font-size: 1.5rem;
            width: 100%;
            z-index: 50;
            outline: none;    
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            box-shadow: none  !important;
            color: $font-clr;
            background-color: white;
            border: 1px solid white;
            &:active,
            &:focus {
                border: 1px solid $font-clr !important;
                outline: none  !important;
                box-shadow: none  !important;
            }
        }
        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="tel"] {
            // height: 40px;
            height: 55px;
        }
        textarea {
            height: 100px;
            padding-top: 2rem;
        }

        &.frm_half,
        &.frm_password {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            &:nth-child(odd) {
                margin-left: 0;
            }
        }

        &.frm_third {
            display: inline-block;
            vertical-align: top;
            width: 33.333%;
            &:first-of-type() {
                margin-left: 0;
            }
        }

        &.frm_two_thirds {
            display: inline-block;
            vertical-align: top;
            width: 66.666%;
            &:first-of-type() {
                margin-left: 0;
            }
        }
    }

    .hs-form-field {
        @include media-breakpoint-down(sm){
            padding: 0 2.5rem;
            
            &.hs-fieldtype-text {
                label {
                    left: 4rem;
                }

                &.form-field-active > {
                    label {
                        left: 4rem;
                    }
                }
            }
        }
    }


    .acf-fields {
        .description {
            margin-top: 5px;
            font-size: 1.3rem;
            font-style: italic;
        }

        .acf-field[data-width]+.acf-field[data-width] {
            border-left: none;
        }
        .acf-field {
            // padding: 0;
            border-top: 0;
            border-left: 0;
            input[type="text"],
            input[type="number"],
            input[type="password"],
            input[type="email"],
            input[type="tel"],
            textarea {
                padding: 1rem 1.5rem 1rem;
            }
            &[data-width]+,
            &[data-width] {
                padding: 0;
            }
            .acf-label {
                label {
                    font-weight: 400;
                    font-size: 1.5rem;
                }
            }
            .acf-repeater {
                .acf-actions {
                    text-align: left;
                    .acf-button {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background-color: transparent;
                        border: none;
                        font-family: Poppins,sans-serif;
                        display: inline-flex;
                        font-size: 17px;
                        line-height: 1;
                        color: #3a3131;
                        font-weight: 500;
                        padding: 1rem 0;
                        position: relative;
                        text-decoration: none;
                        &:before {
                            margin-right: 1.5rem;
                            position: relative;
                            content: '+';
                            top: 0;
                        }
                    }
                }
                .acf-button {
                    @extend .frm_repeat_buttons;
                }
                .acf-row {
                    border-color: #eeeeee;
                    .acf-field {
                        border-left: 1px solid #eeeeee;
                    }
                    .acf-field-textarea {
                       border-top: 1px solid #eeeeee;
                    }
                }
            }
        }
        .acf-table {
            input[type="url"] {
                border: none;
            }
        }
    }
    .acf-form-submit {
        padding: 0 10px;
        display: flex;
        justify-content: flex-end;
        .acf-button {
            font-size: 17px;
            margin-top: 0;
            // transition-property: all;
            // transition-duration: .5s;
            // transition-timing-function: ease-out;
            border: 1px solid transparent;
            padding: 0 0 0 2rem;
            background: 0 0;
            background: url('../assets/images/arrow-icon.svg') no-repeat;
            background-position: left center;
        }
    }

    .frm_form_field {
        &.frm_section_heading {
            padding: 0;
            margin-bottom: 6rem;

            h3 {
                margin-bottom: 2.8rem;
                padding: 0 1rem;
            }

            .frm_form_field {
                // margin-left: -10px;
                // margin-right: -10px;
                padding: 0 1rem;
            }
        }

        &.login-remember {
            label {
                display: block;
            }
        }
    }

    // .form__select {
    //     margin-bottom: 18px;
    //     &.hidden {
    //         display: none;
    //     }
    // }

    // SELECT
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 55px;
        font-weight: 300;
        font-size: 1.5rem;
        width: 100%;
        min-width: 316px;
        padding: 5px 15px;
        display: block;
        position: relative;
        z-index: 25;
        background: url('../assets/images/down-arrow.png') no-repeat;
        background-position: right 18px center;
        background-color: white;
        box-shadow: none  !important;
        border: 1px solid white;
        color: $font-clr;
        &:active,
        &:focus {
            background: url('../assets/images/up-arrow.png') no-repeat;
            background-position: right 18px center;
            background-color: white;
            border: 1px solid $font-clr !important;
            outline: none  !important;
            box-shadow: none  !important;
        }
        option {
            font-weight: 300;
            white-space: pre;
            padding-top: 1rem;
            min-height: 35px;
        }
        @include media-breakpoint-between(xs, sm) {
            min-width: auto;
        }
    }

    @include media-breakpoint-between(xs, sm) {
        // All forms
        .hs-form-field,
        .frm_form_field,
        .sf-field-search {
            &.frm_half,
            &.frm_password {
                width: 100%;
                display: block;
            }

            &.frm_third {
                width: 100%;
            }

            &.frm_two_thirds {
                width: 100%;
            }
        }
        select {
            min-width: 100%;
        }
        .acf-field[style] {
            width: 100%!important;
        }
    }

    // CHECKBOXES
    .hs-form-booleancheckbox,
    .hs-form-checkbox,
    .vertical_radio {
        position: relative;
        margin-top: 10px;
        clear: both;
        color: $font-clr;

        &:not(.frm_radio_field){
            input[type="radio"],
            input[type="checkbox"] {
                border: 1px solid white;
                position: absolute;
                display: block;
                left: 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 18px!important;
                height: 18px;
                background-color: white;
                &:checked {
                    background-color: $font-clr;
                }
                &:active,
                &:focus {
                    border: 1px solid $font-clr !important;
                    outline: none  !important;
                    box-shadow: none  !important;
                }
            }
        }

        .frm_primary_label {
            top: 0;
            left: 0;
            position: relative;
            font-size: 1.5rem;
            margin-bottom: 5px;
            font-weight: 400;
        }

        label {
            font-weight: 300;
            font-size: 1.5rem;
            line-height: 18px;
            padding-left: 30px;
            margin-bottom: 0;
            position: relative;
            a {
                color: inherit;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .frm_checkbox {
            margin-bottom: 2rem;
            // label {
            //     font-size: 1.3rem;
            // }
        }

        &.frm_radio_field {
            margin-bottom: 3rem;

            .frm_radio {
                margin-bottom: 0.5rem;
                label {
                    padding-left: 0;
                    margin: 2px 0;
                    font-size: 1.5rem;
                    padding-left: 2.5rem;
                }

                input {
                    height: 1.5rem;
                    width: 1.5rem;
                    position: absolute;
                    left: 0;
                    // position: relative;
                    top: 2px;
                    margin-right: 5px;
                }

                &:last-of-type {
                    margin-bottom: 1rem;
                    label {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    // CAPTCHA
    .captcha {
        margin-left: 0;
    }

    // SUBMIT
    //.form__submit,
    .hs-submit,
    .frm_submit {
        padding: 0 2.5rem;
        display: flex;
        justify-content: flex-end;

        //margin-top: -6rem;
        //margin-bottom: 6rem;
    }

    //.form__btn,
    .hs-button,
    .frm_button_submit {
        font-size: 17px;
        margin-top: 0;
        // transition-property: all;
        // transition-duration: 0.5s;
        // transition-timing-function: ease-out;
        border: 1px solid $font-clr;
        padding: 1.5rem 3.5rem 1.5rem 5.5rem;
        background: transparent;
        cursor: pointer;

        &:before {
            content: url('../assets/images/arrow-icon.svg');
            margin-right: 1.5rem;
        }

        &:hover {
            background-color: $font-clr;
            color: white;
            &:before {
                content: url('../assets/images/arrow-icon-white.svg');
            }
        }

        &:focus {
            border: 2px solid #000 !important;
            outline: none  !important;
        }
    }
    .frm_button_submit {
        padding-left: 3.5rem;
    }

    .hs-button {
        background-image: url('../assets/images/arrow-icon.svg');
        background-repeat: no-repeat;
        background-position: 3rem 2.2rem;

        &:hover {
            background-image: url('../assets/images/arrow-icon-white.svg');
        }
    }


    // PLACEHOLDERS
    ::-webkit-input-placeholder {
       color: #000 !important;
    }
    ::-moz-placeholder {
       color: #000 !important;
    }
    :-ms-input-placeholder {
       color: #000 !important;
    }
    :-moz-placeholder {
       color: #000 !important;
    }

    // ERRORS
    .hs-error-msg,
    .frm_error,
    .form__error {
        font-size: 1.3rem;
        font-weight: 500;
        margin-top: 5px;
        color: $font-clr;
    }
    .frm_error_style {
        font-size: 1.5rem;
        border: 0;
        border-radius: 0;
        margin-bottom: 10px;
        padding: 0;
        font-weight: 500;
        color: $font-clr;
    }

    .frm_blank_field {
        ::-webkit-input-placeholder {
           color: $font-clr !important;
        }
        ::-moz-placeholder {
           color: $font-clr !important;
        }
        :-ms-input-placeholder {
           color: $font-clr !important;
        }
        :-moz-placeholder {
           color: $font-clr !important;
        }
        input[type="text"],
        input[type="number"],
        input[type="email"],
        input[type="tel"],
        input[type="password"],
        textarea,
        input[type="radio"],
        input[type="checkbox"],
        select {
            border-color: red !important;
        }
    }
    .frm_message {
        color: $font-clr;
    }
    .frm-g-recaptcha {
        display: block;
        margin-top: 2rem;
    }

    .frm_description {
        margin-top: 5px;
        font-size: 1.3rem;
        font-style: italic;
    }

    .frm_section_heading {
        .frm_description {
            margin-left: 1rem;
        }
    }

    .frm_hidden_container {
        .frm_primary_label {
            @extend .visually-hidden;
        }
    }

    .frm_none_container {
        .frm_primary_label {
            display: none;
        }
    }

    .hs-richtext {
        padding: 0 1rem;

        h3 {
            margin-bottom: 3rem;
        }

        @include media-breakpoint-down(sm){
            padding: 0 2.5rem;
        }
    }

    .hs_recaptcha {
        padding-left: 2rem;
        margin-top: 3rem;
    }

    .hs-fieldtype-select {
        width: 100% !important;

        label {
            font-size: 1.5rem;
            top: 0;
            left: 0;
            position: relative;
        }
    }
}

.frm_dropzone {
    padding: 3rem;
    width: 100%;
    background: white;
    border: 2px solid #5f5f5f;
    text-align: center;

    .frm_small_text {
        font-size: 1.3rem;
    }
    button {
        @extend .btn;
    }

    .frm_compact_text {
        display: none;
    }

    .dz-preview {
        font-size: 1.2rem;
    }
}

.pb-form {
    form {
        padding: 5rem;
        &.acf-form {
            padding: 3rem;
        }
    }
    @include media-breakpoint-only(md) {
        form {
            margin-top: 5rem;
            padding: 6rem 2rem;
        }
    }
    @include media-breakpoint-between(xs, sm) {
        form {
            margin-top: 5rem;
            padding: 6rem 2rem;
            &.acf-form {
                padding: 5rem 1rem;
            }
            &.hs-form {
                padding: 3rem 0 6rem;
            }
        }
    }
}

.article-filter,
.pb-marketing-form {
    form {
        // padding-bottom: 5rem;
        //.form__field,
        .frm_form_field,
        .sf-field-search  {
            input[type="text"],
            input[type="number"],
            input[type="email"],
            input[type="password"],
            input[type="tel"],
            textarea {
                color: $font-clr;
                background-color: white;
                border: 1px solid $font-clr;
                &:active,
                &:focus {
                    border: 1px solid $bg-gold !important;
                }
            }
        }
        // SELECT
        select {
            background-color: white;
            border: 1px solid $font-clr;
            color: $font-clr;
            &:active,
            &:focus {
                background-color: white;
                border: 1px solid $bg-gold !important;
            }
        }
        // CHECKBOXES
        //.form__checkbox,
        .vertical_radio {
            color: $font-clr;
            input[type="radio"],
            input[type="checkbox"] {
                border: 1px solid $font-clr;
                &:checked {
                    background-color: $font-clr;
                }
                &:active,
                &:focus {
                    border: 1px solid $bg-gold !important;
                }
            }
        }

        // SUBMIT
        //.form__submit,
        // .frm_submit {
        //     right: 0;
        //     bottom: 0;
        // }

        //.form__btn,
        .frm_button_submit {
            @extend .btn;
            margin-top: 0;
            &:focus {
                border: 1px solid $font-clr !important;
                outline: none  !important;
            }
        }

        // ERRORS
        .frm_error,
        .form__error {
            color: $bg-gold;
        }
        .frm_error_style {
            color: $bg-gold;
        }
        .frm_blank_field {
            ::-webkit-input-placeholder {
               color: $bg-gold !important;
            }
            ::-moz-placeholder {
               color: $bg-gold !important;
            }
            :-ms-input-placeholder {
               color: $bg-gold !important;
            }
            :-moz-placeholder {
               color: $bg-gold !important;
            }
            input[type="text"],
            input[type="email"],
            input[type="number"],
            input[type="password"],
            input[type="tel"],
            textarea,
            input[type="radio"],
            input[type="checkbox"],
            select {
                border-color: $bg-gold !important;
            }
        }
        .frm_message {
            color: $bg-gold;
        }
    }
}

.frm_message {
    padding: 5rem 6rem 6rem;
    p {
        @extend .delta;
        text-align: center;
    }
}

// .searchandfilter ul li ul li ul {
//     margin-left: 20px;
// }

.article-filter {
    position: relative;
    top: -6rem;
    margin-bottom: -6rem;

    @include media-breakpoint-down(sm) {
        top: 0;
        margin-top: 4rem;
        margin-bottom: 0;
    }
    &--dashboard {
        top: 0;
        margin-bottom: 0;
        padding-right: 3rem;
        .article-filter__filters  {        
            h4 {
                @extend .gamma;
                padding-top: 0;
            }
            .searchandfilter {
                ul {
                    margin-bottom: 2rem;
                    // margin-bottom: 4rem;
                    li {
                        font-size: 13px;
                        padding: 1rem 0 1rem 0;
                        position: relative;
                        width: 100%;
                        .sf-input-checkbox {
                            position: absolute;
                            height: 18px;
                            width: 18px;
                            appearance: none;
                            background-color: transparent;
                            border: 1px solid $font-clr;
                            &:checked {
                                background-color: $font-clr;
                            }
                        }
                        .sf-label-checkbox {
                            padding-left: 30px;
                            padding-right: 30px;
                            width: 100%;
                        }
                        .children {
                            margin-top: 1rem;
                            margin-bottom: 0;
                            margin-left: 3rem;
                        }
                        .toggle {
                            position: absolute;
                            top: 4px;
                            right: 0;
                            width: 20px;
                            height: 20px;
                            font-size: 0;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            &:after {
                                content: url('../assets/images/arrow-icon.svg');
                                display: block;
                                transform: rotate(-90deg);
                                position: absolute;
                                top: 20%;
                                left: 50%;
                            }
                            &.closed {
                                justify-content: flex-end;
                                &:after {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                    ul {
                        ul {
                            margin-left: 2rem;
                        }
                    }
                }
                .sf-field-reset {
                    .search-filter-reset {
                        @extend .btn;
                        background: $bg-gold url('../assets/images/arrow-icon.svg') no-repeat;
                        background-position: 15px center;
                        margin-top: 0;
                        margin-bottom: 3rem;
                        &:after {
                            display: none;
                        }
                        &:hover {
                            background: $font-clr url('../assets/images/arrow-icon-white.svg') no-repeat;
                            background-position: 15px center;
                        }
                    }
                }
            }
            .sf-field-search {
                margin-top: 2rem;
                padding-top: 6rem;
                &:before {
                    content: '';
                    display: block;
                    height: 1px;
                    background-color: $bg-stone;
                    width: 100%;
                    position: relative;
                    top: -3rem;
                }

                label {
                    min-width: 0;
                    width: 100%;
                }
            }
        }
    }
    &__button {
        top: -2rem;
        margin-bottom: -2rem;
        .btn {
            margin-top: 0;
            float: right;
        }
    }
    &__filters {
        ul {
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                padding-right: 2.6rem;
                &:last-of-type {
                   padding-right: 0; 
                }
                &.sf-field-search {
                    .sf-input-text {
                        padding: 3px 15px;
                        background: white url('../assets/images/search-icon.svg') no-repeat;
                        background-position: calc(100% - 10px) 50%;
                    }
                }
            }
        }
        h4 {
            font-family: $font-family;
            font-size: 1.5rem;
        }
        label,
        select {
            min-width: 315px;
        }
    }

    @include media-breakpoint-only(md) {
        &--dashboard {
            .article-filter__filters  {        
                h4 {
                    font-size: 18px;
                }
            }
            label,
            select {
                min-width: unset;
                width: 100%;
            }
        }
    }

    @include media-breakpoint-between(xs, sm) {
        &--dashboard {
            padding-right: 1.5rem;
            .article-filter__filters  {
                .sf-field-search {
                    margin-top: 1rem;
                    padding-top: 3rem;
                    padding-bottom: 5rem;
                    &:before {
                        top: -3rem;
                    }
                }
            }
            label,
            select {
                min-width: unset;
                width: 100%;
            }
        }
        &__button {
            width: 100%;
            .btn {
                float: none;
            }
        }
    }
}

.pb-marketing-form {
    form {
        .vertical_radio {
            .frm_primary_label {
                margin-bottom: 0;
                font-size: 0;
            }
        }
        .frm_submit {
            position: absolute;
            bottom: 0;
            right: -10px;

            /* Portrait and Landscape */
            @media only screen 
              and (min-device-width: 768px) 
              and (max-device-width: 1024px) 
              and (-webkit-min-device-pixel-ratio: 1) {
                position: relative;
            }
        }
    }
    @include media-breakpoint-between(xs, sm) {
        form {
            .frm_submit {
                position: relative;
                justify-content: flex-start;
                bottom: auto;
                right: auto;
            }
        }
    }
}

.form--no-padding {
    form {
        .frm_submit {
            bottom: 0;
            right: -10px;
        }
    }
    .frm_message {
        padding: 0; 
    }
}

.ff-login-form {
    h3 {
        padding: 5rem 6rem 0;

        @include media-breakpoint-down(sm) {
            padding: 3rem 3rem 0;
        }
    }

    .frm_checkbox {
        margin-top: -4rem;
        width: 50%;
        position: relative;
        z-index: 2;

        label {
            font-size: 1.3rem;
        }

        input {
            margin-right: 1rem;
            position: relative;
            top: 2px;
            height: 1.6rem;
            width: 1.6rem;
        }
    }

    .frm_submit {
        position: relative;
        z-index: 2;
        input[type="submit"] {
            @extend .btn;
            border: none;
            cursor: pointer;
        }
    }

    @include media-breakpoint-down(sm) {
        form {
            margin-top: 1rem;
        }
    }
}

// Date picker
.ui-datepicker {
    .ui-widget-header {
        background: $bg-gold!important;
    }
    .ui-datepicker-header {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important;
    }
    thead {
        color: $font-clr !important;
        background: $bg-gold-tint !important;
    }
    th {
        font-weight: normal !important;
    }
    .ui-datepicker-title {
        padding: 5px 0 !important;
    }
    select {
        width: 39.5% !important;
        border-color: white !important;
    }
    .ui-datepicker-next,
    .ui-datepicker-prev {
        width: 10% !important;
        display: inline-flex !important;
        justify-content: center !important;
        align-items: center !important;
        &:before {
            display: none;
        }
    }
    .ui-datepicker-next {
        transform: none !important;
        &:after {
            display: block;
            content: url(../assets/images/arrow-icon.svg);
        }
    }
    .ui-datepicker-prev {
        transform: rotate(180deg) !important;
        &:after {
            display: block;
            content: url(../assets/images/arrow-icon.svg);
        }
    }
    .ui-state-default.ui-state-hover {
        color: $font-clr;
        background: $bg-gold-tint !important;
    }
    .ui-state-default.ui-state-active {
        background: $bg-gold !important;
    }
}

// Signature 
.frm_form_field {
    .sigPad {
        ul.sigNav {
            li.drawIt,
            li.typeIt {
                a {
                    position: relative;
                    background-color: $bg-gold-tint;
                    width: 35px;
                    height: 35px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    &.current {
                        background-color: $bg-gold;
                    }
                    &:before {
                        position: absolute;
                        display: block;
                        z-index: 5;
                        height: 100%
                    }
                }
                i {
                    display: none;
                }
            }
            li.drawIt {
                a {
                    &:before {
                        content: url(../assets/images/pen-icon.svg);
                        width: 35px;
                        height: 35px;
                    }
                }
            }
            li.typeIt {
                a {
                    &:before {
                        content: url(../assets/images/keyboard-icon.svg);
                        width: 25px;
                        height: 30px;
                    }
                }
            }
        }
    }
}

#content form .frm_form_field .sigPad .typed {
    width: 71%;
    height: 43px;
    top: 5rem;
    margin-left: 2rem;

    input[type="text"] {
        font-size: 1.5rem !important;
        font-family: Poppins,sans-serif !important;
        border: 1px solid #cccccc !important;
    }
}

.submitted-message {
    padding: 8rem 5rem;
}


//FF login and forgot password forms
#frm-reset-password-0 {
    .frm_description {
        padding: 0 1rem 2rem;
    }

    .frm_form_field {
        max-width: calc(100% - 1.5rem);
    }

    form .frm_primary_label {
        top: 0;
        left: 0;
        position: relative;
        font-weight: 400;
        font-size: 1.5rem;
    }
}

#loginform-0 {
    form .frm_submit {
        padding: 0 1rem 0 2.5rem;
    }

    form .frm_primary_label {
        top: 0;
        left: 0;
        position: relative;
        font-weight: 400;
        font-size: 1.5rem;
    }
}

.link--password-reset {
    margin-left: 6rem !important;
    margin-bottom: 0rem;
    position: relative;
    top: -5rem;

    @include media-breakpoint-down(md) {
        margin-left: 3rem !important;
    }
}

.form-progress {
    margin: 0 5rem 0;
    padding-bottom: 5rem;

    @include media-breakpoint-down(md) {
        margin: 0 2rem 0;
        padding: 0 0 5rem;
    }

    &__btn {
        &:disabled {
            opacity: 0.65;
        }
    }

    &__email {
        padding: 1rem 1.5rem 1rem;
        display: block;
        font-weight: 300;
        font-size: 1.5rem;
        width: 100%;
        z-index: 50;
        outline: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none !important;
        color: #3a3131;
        background-color: #fff;
        border: 1px solid #fff;
        height: 55px;
        margin-top: 2rem;
    }

    &__inner {
        display: none;
    }

    &--open {
        .form-progress__inner {
            display: block;
        }
    }
}

.StripeElement {
    background: white;
    padding: 2rem;
    margin-top: 6px;
}

.single-alumni-events {
    form .frm_error_style {
        color: red;
        margin-top: 3rem;
    }

    form .form__error, form .frm_error, form .hs-error-msg {
        color: red;
    }
}