//******************************************************************************
// ARTICLES
//******************************************************************************

.article-grid {
	a {
        &:not([class]) {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
	&__masonry-area {
		display: none;
		margin-top: 5rem;

		&--show {
			display: flex !important;
		}
	}

	&__dashboard {
		margin-top: 8rem;
		.cards {
			margin-top: 0;
		}
	}

	.grid-sizer {
		@include make-col-ready();
		@include media-breakpoint-up(sm) {
			@include make-col(12);
		}
		@include media-breakpoint-up(md) {
			@include make-col(6);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(4);
		}
	}

	.link {
		margin-top: 2rem;
	}

	&__image {
		margin-bottom: 0;

		img {
			width: 100%;
			max-width: auto;
		}
	}

	&__inner {
		display: block;
		padding: 4rem 3rem;
		margin-bottom: 3rem;
		p {
			margin-bottom: 2rem;
		}
	}
	&__info {
		p {
			margin: 0;
		}
		a {
			@extend .link;
		}
	}
	&__meta {
		font-size: 1.4rem;
		margin-bottom: 2rem;
	}

	&--masonry {
		.article-grid__item {
			position: relative;
			z-index: 2;
			$colors: $bg-gold-tint, $bg-blue-tint, $bg-green-tint, $bg-purple-tint, $bg-orange-tint;
			@for $i from 1 through length($colors) {
				&:nth-child(#{length($colors)}n+#{$i}) {
					.article-grid__inner {
						background: nth($colors, $i);
					}
				}
			}
		}
	}
	@include media-breakpoint-between(xs,md) {
		&__dashboard {
			margin-top: 5rem;
		}
	}
}

.pagination {
	margin-top: 4rem;
	.nav-links {
		display: inline-flex;
	    justify-content: center;
	    align-items: center;
	}
	.page-numbers {
		padding: 0 1rem;
		font-size: 2rem;
		font-weight: 300;
		color: $font-clr;
		text-decoration: none;
		&.current {
			font-weight: 500;
		}
		&:hover {
			color: $bg-gold;
		}
	}
	.next,
	.prev {
	    font-size: 0;
		border: 0;
		// width: 20px;
		// height: 20px;
		background-color: transparent;
		z-index: 5;
		padding: 0;
		&:focus {		
			outline: none;
	  		box-shadow: none;
		}
		&:before {
			// position: absolute;
	    	content: '';
	    	width: 0;
			height: 0;
			border-style: solid;
		}
		&:hover {
			cursor: pointer;
		}
	}
	.next {
		margin-left: 1rem;
		&:before {
			border-width: 7px 0 7px 8px;
			border-color: transparent transparent transparent $font-clr;
			right: 0;
		}
		&:hover {
			&:before {
				border-color: transparent transparent transparent $bg-gold;
			}
		}
	}
	.prev {
		margin-right: 1rem;
		&:before {
			border-width: 7px 8px 7px 0px;
			border-color: transparent $font-clr transparent transparent;
			left: 0;
		}
		&:hover {
			&:before {
				border-color: transparent $bg-gold transparent transparent;
			}
		}
	}
}

// EPrints

body.single-eprints-journals {


	.pb-hero {
		
		h1 {
			margin-bottom: 2rem;
		}

		.pb-hero__left {
			min-width: 80% !important;
		}

	}

}

.pb-single-eprints-journals {

	+ .pb-marketing-form.pb-eprints-form {
		margin-top: 0;
	}

	&.pb {

		padding-top: 0.5*$pbPadding; 
		padding-bottom: 0.5*$pbPadding;

		@include media-breakpoint-down(md) {
			padding-top: 0.5*$pbPadding-tablet;
			padding-bottom: 0.5*$pbPadding-tablet;
		}

	}

	&__top {


	}

	&__table {

		tr {

			td:first-child {
				padding-right: 4rem;
			}

		}

	}

	&__bottom {
		margin-top: 6rem;
	}

	&__abstract {

	}

	&__html {

	}

	&__row {

		@include media-breakpoint-up(lg) {
			flex-direction: row-reverse;
		}

	}

	&__cards {

		@include media-breakpoint-down(md) {
			margin-bottom: 3rem;
		}

		.card {
			margin-bottom: 2rem;
		}

	}

	&__slick {

	}

	&__slide {
		position: relative;
		display: block;
		overflow: hidden;
		width: 100%;
		max-width: 800px;
		box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
		margin-bottom: 4rem;

		@include media-breakpoint-down(sm) {
			margin-bottom: 2rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:after {
			content: 'Page 1';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			z-index: 3;
			text-align: center;
			padding: 0.5rem;
			font-size: 1.2rem;
			color: #000000;
		}

		@for $i from 1 through 50 {
		    &:nth-child(#{$i}) {
		      list-style: none;
		      &:after{
		        content: 'Page #{$i}';
		      }
		    }
		  }

		img {
			display: block;
			position: relative;
			object-fit: contain;
			width: 100%;
			height: auto;
			// transform: scale(1.1);
			transform-origin: center;
			z-index: 1;
		}

	}


}
