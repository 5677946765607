//******************************************************************************
// NAVIGATION
// All kind of navigation and menus, including breadcrumb.
//******************************************************************************

.site-header {
    .site-header__row {
        position: relative;
    }
    &.open {
        .site-header__row {
            display: block;
        }
        .site-header__navs {
            display: flex;
            flex-direction: column-reverse;
        
            .collapse {
                display: block;
            }
        }
    }

    @include media-breakpoint-between(xs, md) {
        .site-header__navs {
            display: none;
        }
    }
}

.main-nav {
    .navbar {
        width: 100%;
        padding: 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
            }
        }

        // GENERIC NAVIGATION LINK
        .menu-item {
            position: relative;
            > a {
                width: 100%;
                font-weight: 400;
                color: $font-clr;
                padding: 1rem 1.5rem;
                display: inline-block;
            }
            &.current-menu-parent,
            &.show,
            &:hover {
                background-color: $bg-stone;
                > a {
                    text-decoration: none;
                }
            }

            .menu-icon {
                margin-left: 1rem;
                max-height: 16px; // Required for navigation height
                vertical-align: baseline;
                // &.svg {
                //     rect,
                //     path,
                //     line {
                //         stroke: white!important;
                //     }
                // }
            }
        }

        // GENERIC SUB NAVIGATION
        .dropdown-menu {
            padding: 0;
            border: 0;
            border-radius: 0;
            margin-top: 0;
            background: #fff;
            min-width: 380px;
            border: 1px solid $bg-stone; 

            .container {
                padding-left: 0;
                padding-right: 0;
            }

            .row {
                @extend .no-gutters;
                padding-left: 0;
            }

            .menu-item {
                width: 100%;
                .dropdown-menu {
                    border: none;
                    .menu-item {
                        width: 100%;
                    }
                }
            }
        }

        // TOP MENU INCLUDING ALUMNI
        &--mini {
            margin-bottom: 3rem;
            .menu-item {
                border-top: 1px solid $bg-stone;
                border-left: 1px solid $bg-stone;
                border-bottom: 1px solid $bg-stone;
                > a {
                    display: block;
                    font-size: 1.6rem;
                }
                // TIER TWO NAVIGATION
                .dropdown-menu {
                    left: -1px; // Account for the parent border
                    // TIER TWO LINK
                    .menu-item {
                        border-top: none;
                        border-left: none;
                        &:last-of-type {
                            border-bottom: none;
                        }
                        &.current-menu-item,
                        &:hover {
                            background-color: $bg-stone;
                        }
                    }
                }
            }
        }

        &--alumni-nav {
            display: flex;
            flex-direction: row;

            .menu-item {
                background-color: $bg-brown;
                > a {
                    color: white;
                }
                &:hover {
                    background-color: $font-clr;
                    > a {
                        color: white;
                    }
                }
            }

            .menu {
                font-size: 0;
            }
        }

        &--dashboard {
            display: flex;
            flex-direction: row;

            .menu-item {
                background-color: white;
                border-top: 1px solid $bg-stone;
                border-left: 1px solid $bg-stone;
                border-bottom: 1px solid $bg-stone;
                &:last-of-type {
                    border-right: 1px solid $bg-stone;
                }
                
                > a {
                    color: $font-clr;
                }
            }
        }

        // MAIN NAVIGATION
        &--main {
            .menu-item {
                > a {
                    font-size: 1.9rem;
                }
                &.menu-btn {
                    margin-left: 1.8rem;
                    a {
                       @extend .btn;
                       font-size: 1.9rem;
                       margin-top: 0;
                       padding: 1.5rem 2rem;
                    }
                }
                .menu-icon {
                    &.svg {
                        rect,
                        path,
                        line {
                            stroke: white!important;
                        }
                    }
                }
                // TIER TWO NAVIGATION
                .dropdown-menu {
                    // TIER TWO LINK
                    .menu-item {
                        border-bottom: 1px solid $bg-stone;
                        &:last-of-type {
                            border-bottom: none;
                        }
                        // TIER THREE NAVIGATION
                        .dropdown-menu {
                            position: relative;
                            // TIER THREE LINK
                            .menu-item {
                                border-bottom: 1px solid $bg-stone;
                                &:first-of-type {
                                    border-top: 1px solid $bg-stone;
                                }
                                &:last-of-type {
                                    border-bottom: none;
                                }
                                > a {
                                    // font-size: 1.6rem;
                                    padding-left: 4rem;
                                }
                            }
                        }

                        // TOGGLE
                        .mega-dropdown-toggle {
                            &:after {
                                content: '+';
                                display: inline-block;
                                width: 20px;
                                height: 20px;
                                float: right;
                            }
                            &--open {
                                &:after {
                                    content: '-';
                                }
                            }
                        }
                        &.show {        
                            .mega-dropdown-toggle {
                            }
                        }
                    }
                    .nav-megamenu__menu {
                        width: 100%;
                    }
                }
            }
        }
    }


    @include media-breakpoint-only(md) {
        .site-header__navs {
            position: absolute;
            min-width: 690px;
        }
    }

    @include media-breakpoint-between(xs, sm) {
        > .container {
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;
            // border-bottom: 1px solid $bg-stone;
        }

        .logo-container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    @include media-breakpoint-between(xs, lg) {
        .site-header__navs {
            &--dashboard {
                .navbar {
                    &--dashboard {
                        .menu-item {
                            > a {
                                font-size: 0;
                            }
                            .menu-icon {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-between(xs, md) {
        .site-header__navs {
            &--dashboard {
                position: fixed;
                bottom: 0;
                left: 0;
                min-width: 100%;
                // min-width: unset;
                .navbar {
                    ul {
                        li {
                            width: unset;
                            min-width: unset;
                        }
                    }
                    &--dashboard {
                        // display: flex;
                        // flex-direction: column;
                        .menu-item {
                            border: none;
                            text-align: left;
                            background: $bg-gold;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-between(xs, md) {
        .logo-container {
            .logo {
                padding-top: 20px;
                padding-bottom: 20px;
                max-width: 40px;
            }
        }
        .navbar {
            // GENERIC NAVIGATION LINK
            ul {
                li {
                    width: 100%;
                    min-width: 100%;
                }
            }

            .menu-item {
                text-align: right;
                a {
                    padding: 0.5rem 3.5rem 0.5rem 1.5rem;  
                }
                &.dropdown {
                    > a {             
                        &:after {
                            content: '+';
                            position: absolute;
                            width: 20px;
                            height: 20px;
                        }
                    }
                    &.show {
                        > a {                        
                            &:after {
                                content: '-';
                            }
                        }
                    }
                }
                &.current-menu-parent,
                &.show,
                &:hover {
                    background-color: transparent;
                    a {
                        background-color: transparent !important;
                    }
                }
                a {
                    &:focus,
                    &:active {
                        background-color: transparent !important;
                    }
                }
            }

            // GENERIC SUB NAVIGATION
            .dropdown-menu {
                background: transparent;
                border: none;
                min-width: unset;
            }

            // TOP MENU INCLUDING ALUMNI
            &--mini {
                background: $bg-blue;
                padding: 1.5rem 0 0 0;
                margin-bottom: 0;
                .menu-item {
                    border: none;
                    > a {
                        font-size: 1.9rem;
                        font-weight: 500;
                        color: $font-clr;
                    }
                    .menu-item {
                        > a {
                            padding-top: 0;
                            font-weight: 400;
                        }
                    }
                    .menu-icon {
                        position: absolute;
                        right: 1.5rem;
                        top: 1rem;
                        margin-left: auto;
                        &.svg {
                            rect,
                            path,
                            line {
                                stroke: $font-clr !important;
                            }
                        }
                    }
                }
            }

            &--alumni-nav {
                display: block;
                background: $bg-green;
                margin-top: 1.5rem;
                width: 100%;
                .menu-item {
                    background: transparent;
                }
            }

            // MAIN NAVIGATION
            &--main {
                background: $bg-gold;
                padding: 1.5rem 0;
                .menu-item {
                    > a {
                        font-weight: 500;
                    }
                    &.menu-btn {
                        margin-left: auto;
                        a {
                            width: auto;
                            text-align: right;
                            padding: 1rem 3.5rem 1rem 1.5rem;
                            &:before {
                                display: none;
                            }
                        }
                    }
                    // TIER TWO NAVIGATION
                    .dropdown-menu {
                        padding-bottom: 0.5rem;
                        // TIER TWO LINK
                        .menu-item {
                            border-bottom: none;
                            background: transparent;
                            > a {
                                padding-top: 0;
                                font-weight: 400;
                            }
                            // TIER THREE NAVIGATION
                            .dropdown-menu {
                                padding-bottom: 0.5rem;
                                // TIER THREE LINK
                                .nav-megamenu__menu {
                                    width: 100%;
                                }
                                .menu-item {
                                    border-bottom: none;
                                    &:first-of-type {
                                        border-top: none;
                                    }
                                    &:last-of-type {
                                        border-bottom: none;
                                    }
                                    > a {
                                        padding-top: 0;
                                        font-weight: 200;
                                        padding-left: 0;
                                    }
                                }
                            }

                            // TOGGLE
                            .mega-dropdown-toggle {
                                &:after {
                                    position: absolute;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .menu-alumni-nav-container {
        margin-top: 3rem;
    }
}

@include media-breakpoint-down(md) {
    .site-header {
        &--alumni {
            .site-header__navs {
                display: block;
                width: 100%;
                position: fixed;
                bottom: 0;
                left: 0;
                .collapse {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    .navbar--dashboard .menu-alumni-nav-container {
        .navbar-nav {
            flex-direction: row;
           
            li {
                min-width: auto !important;
                flex-basis: 20%;
                max-width: 20%;
                font-size: 0;
                background-color: $bg-gold;
                border: none;

                &:hover {
                    background-color: darken($bg-gold, 15%);
                }

                &.alumni-logout-menu-btn {
                    display: none;
                }

                a {
                    text-align: center;
                    padding: 1.5rem 3.5rem 1.5rem 1.5rem;
                }

                .menu-icon {
                    height: 2.4rem;
                    max-height: 2.4rem !important;
                    max-width: 30px !important;
                    width: 100%;
                }
            }
        }
    }
}

html[data-useragent*="rv:11.0"] {
    .main-nav .navbar .dropdown-menu .row {
        max-width: 40rem;
    }
    .main-nav .navbar--main .menu-item.menu-btn a:before {
        display: none;
    }
}

// MAIN NAVIGATION TOGGLE
.navbar-toggler {
    display: none;
    @include media-breakpoint-only(md) {
        right: 0;
    }
    @include media-breakpoint-between(xs, sm) {
        right: 15px; 
    }
    @include media-breakpoint-between(xs, md) {
        border: 0;
        border-radius: 0;
        position: absolute;
        top: 22px;
        display: block;
        background: $bg-gold;
        padding: 1.5rem 2rem;
        color: $bg-brown;
        .navbar-toggler-text {
            margin-right: 1rem;
        }
        .navbar-toggler-icon {
            position: relative;
            display: inline-block;
            width: 1.5rem;
            height: 0.15rem;
            background: $bg-brown;
            transition: all .2s ease-in-out;
            &:before,
            &:after {
                background: $bg-brown;
                content: '';
                display: block;
                width: 1.5rem;
                height: 0.15rem;
                position: absolute;
                transition: all .2s ease-in-out;
            }
            &:before {
                top: -0.5rem;
            }
            &:after {
                top: 0.5rem;
            }
        }
        &--close {
            color: white;
            background: $bg-brown;
            .navbar-toggler-icon {
                background: transparent;
                &:before,
                &:after {
                    background: white;
                    top: 0;
                    height: 0.15rem;
                }
                &:before {    
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                &:after {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }  
        }
    }
}
