.pb-hero-anchor-links {
	&__top-row {
		align-items: center;
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
	.breadcrumbs {
		position: absolute;
		#breadcrumbs {
			margin-top: 47px;
			@include media-breakpoint-down(md) {
				margin: 0;
			}
		}
		@include media-breakpoint-down(md) {
			position: relative;
			margin-top: 30px;
		}
	}
	&__left {

		.hero__inner {
			.name-qualifications {
				border-bottom: 1px solid #000;
				padding-bottom: 13px;
				h1 {
					margin-top: 89px;
					margin-bottom: 0;
					@include media-breakpoint-down(md) {
						margin-top: 40px;
					}
				}
				.member-qualifications {
					font-size: 17px;
					font-weight: 300;
					line-height: 27.2px;
				}
			}
			.member-position {
				margin-top: 12px;
				margin-bottom: 0;
			}
			.member-contact-details {
				margin-top: 28px;
				.member-telephone {
					font-size: 14px;
					font-weight: 300;
					line-height: 21px;
					display: flex;
					color: inherit;
					&::before {
						content: '';
						width: 18px;
						height: 18px;
						background-image: url('../assets/images/call_icon.svg');
						background-repeat: no-repeat;
						display: flex;
						margin-right: 11px;
					}
				}
				.member-email {
					font-size: 14px;
					font-weight: 300;
					line-height: 21px;
					display: flex;
					color: inherit;
					margin-top: 8px;
					&::before {
						content: '';
						width: 18px;
						height: 18px;
						background-image: url('../assets/images/mail_icon.svg');
						background-repeat: no-repeat;
						display: flex;
						margin-right: 11px;
					}
				}
			}
			.member-socials {
				max-width: 96px;
				display: flex;
				justify-content: flex-start;
				gap: 12px;
				margin-top: 8px;
			}
			.specialism-areas,
			.external-roles {
				margin-top: 28px;
				.content-title {
					font-size: 17px;
					font-style: normal;
					font-weight: 500;
					line-height: 17px;
					margin-bottom: 5px;
				}
			}
			.external-roles {
				margin-bottom: 47px;
			}
		}
		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}
	&__right {
		img {
			width: 100%;
			// -webkit-clip-path: polygon(calc(100% - 60px) 0, 100% 60px, 100% 100%, 60px 100%, 0 calc(100% - 60px), 0 0);
		    // clip-path: polygon(calc(100% - 60px) 0, 100% 60px, 100% 100%, 60px 100%, 0 calc(100% - 60px), 0 0);
			object-fit: cover;
		    max-width: 100%;
			min-height: 500px;
			max-height: 500px;
		    @media screen and (min-width: 1820px) {
				min-height: 632px;
		    	max-height: 632px;
			}
			@include media-breakpoint-down(lg) {
				max-width: 100%;
				min-height: 500px;
				max-height: 500px;
			}
		    @include media-breakpoint-down(md) {
				max-width: 100%;
				margin-top: 28px;
				min-height: 632px;
			    max-height: 632px;
			}
			@include media-breakpoint-down(sm) {
				max-width: 100%;
				min-height: 0;
				max-height: 368px;
			}
		}
		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}
	@include media-breakpoint-down(md) {
		margin-top: 33px!important;
	}
}

.hero-achor-links {
	position: sticky;
	top: 0px;
	background-color: #fff;
	z-index: 1;
	&.has-header-sitewide-cta {
		top: 80px;
	}
	.anchors {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			a {
				text-decoration: none;
			}
			li {
				list-style: none;
				padding: 15px;
				
				// &.nav-link.bg-green {
				// 	background-color: transparent;
				// 	&:hover {
				// 		background-color: revert;
				// 	}
				// }
			}
		}
	}
	@include media-breakpoint-down(md) {
		top: 0px!important;
	}
	@include media-breakpoint-down(sm) {
		display: none;
	}
	&__mobile {
		display: none;
		

		select {
			width: 100%;
			height: 57px;
			border: 1px solid #000;
			font-size: 17px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-top: 32px;
			padding: 0 8px;
			appearance: none;

		}
		.custom-select {
			background-color: #fff;
			z-index: 1;
			margin-top: 0;
			&::after {
				content: ''; 
	            position: absolute;
	            width: 12px;
	            height: 7.4px;
	            top: 58px;
	            right: 25px; 
	            background-image: url('../assets/images/chevron-down.svg');
	            .section-jump {
	            	margin-top: 0;
	            }
			}

			@include media-breakpoint-down(md) {
				margin-top: 34px;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 0px;
			}
		}
		@include media-breakpoint-down(sm) {
			display: flex;
			position: sticky;
			top: 0;
			z-index: 1;
		}
	}
}

.user-biography {
	padding: 80px 0;
	scroll-margin-top: 100px;
	h2 {
		text-align: center;
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		line-height: 48px;
		@include media-breakpoint-down(md) {
			font-size: 32px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 29px;
		}
	}
	.pb-generic-text__content {
		@include media-breakpoint-down(sm) {
			margin-bottom: 32px;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 5rem 0;
		scroll-margin-top: 0;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 32px;
		scroll-margin-top: 100px;
	}
}

.user-publications {
	padding-top: 50px;
	padding-bottom: 50px;
	margin-top: 80px;
	scroll-margin-top: 100px;
	h2 {
		text-align: center;
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		line-height: 48px;
		@include media-breakpoint-down(md) {
			font-size: 32px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 29px;
		}
	}
	.publication-row {
		border-bottom: 1px solid rgba(104, 77, 59, 0.30);
		padding: 24px 0;

		&:last-of-type {
			border-bottom: none;
			padding-bottom: 0;
		}

		.publication-date {
			font-size: 14px;
			font-style: normal;
			font-weight: 300;
			line-height: 21px;
			margin-bottom: 6px;
		}
		.publication-name {
			font-feature-settings: 'liga' off, 'clig' off;
			font-size: 17px;
			font-style: normal;
			font-weight: 500;
			line-height: 25.5px;
			text-decoration-line: underline;
			text-decoration-style: solid;
			margin-bottom: 6px;
			color: inherit;
		}
		a.publication-name:hover {
			text-decoration-line: underline;
			text-decoration-style: solid;
		}
		.publication-description {
			font-size: 17px;
			font-style: normal;
			font-weight: 300;
			line-height: 27.2px;
		}	
	}

	@include media-breakpoint-down(md) {
		margin-top: 0px!important;
		scroll-margin-top: 0;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 32px;
		scroll-margin-top: 100px;
	}
}
.user-events {
	padding: 80px 0 50px 0;
	margin-top: 80px;
	scroll-margin-top: 100px;
	h2 {
		text-align: center;
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		line-height: 48px;
		@include media-breakpoint-down(md) {
			font-size: 32px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 29px;
		}
	}
	.event-row {
		border-bottom: 1px solid rgba(104, 77, 59, 0.30);
		padding: 24px 0;

		&:last-of-type {
			border-bottom: none;
			padding-bottom: 0;
		}

		.event-date {
			font-size: 14px;
			font-style: normal;
			font-weight: 300;
			line-height: 21px;
			margin-bottom: 6px;
		}
		.event-name {
			font-feature-settings: 'liga' off, 'clig' off;
			font-size: 17px;
			font-style: normal;
			font-weight: 500;
			line-height: 25.5px;
			text-decoration-line: underline;
			text-decoration-style: solid;
			margin-bottom: 6px;
			color: inherit;

		}
		.event-description {
			font-size: 17px;
			font-style: normal;
			font-weight: 300;
			line-height: 27.2px;
			scroll-margin-top: 20px;
		}
	}
	@include media-breakpoint-down(md) {
		scroll-margin-top: 0;
	}
}

.user-publications + .user-events {
	margin-top: -50px;
}