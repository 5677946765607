//******************************************************************************
// BASE
// html, body & default styles
//******************************************************************************
html {
    scroll-behavior: smooth;
    font-size: 62.5%;
}

body {
    color: $font-clr;
    background-position: 0 800px;
    font-family: $font-family;
    font-weight: 300;
    &.admin-bar {
        .site-header:not(.site-header--hidden) {
            top: 34px;
        }
    }
}

strong,
b,
.strong {
    font-weight: 500;
}

p {
    font-size: 1.7rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    strong {
        font-weight: 500; 
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}

h2 + p,
h3 + p,
h4 + p,
h5 + p {
    margin-top: 2rem;
}
.pb-generic-text__content h2+h3 {
    margin-top: 2rem;
}

small {
    display: inline-block;
    font-size: 1.3rem;
}

ul,
ol {
    font-size: 1.7rem; //was 1.5
    margin-bottom: 1.1em;
}

figcaption {
    font-size: 1.7rem; //was 1.5
    margin-bottom: 1.1em;
    width: 100%;
    padding: 1;
    text-align: center;
    padding-top: 1rem;
}

.content p {
    font-size: 1.7rem; //was 1.6
    margin-bottom: 1.2em;
}

main {
    a {
        &:not([class]) {
            color: inherit;
            font-weight: 500;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        &[href^="tel:"] {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

button {
    cursor: pointer;
}


//COOKIES
#hs-eu-cookie-confirmation {    
    box-shadow: none!important;
    border: none!important;
    background-color: $bg-green-tint!important;

    #hs-eu-cookie-confirmation-inner {
        font-family: $font-family!important;
        background-color: transparent!important;
        max-width: 1350px!important;
        p {
            color: $font-clr!important;
            font-size: 1.5rem!important;
            line-height: 1.5!important;
            a {
                color: inherit!important;
                background-color:$bg-green-tint!important;
                border: none;
            }
        }

        #hs-en-cookie-confirmation-buttons-area {
            #hs-eu-confirmation-button,
            #hs-eu-decline-button {
                font-family: $font-family!important;
                background-color: transparent!important;
                color: $font-clr!important;
                font-size: 17px!important;
                font-weight: 500!important;
                border: none!important;
                &:before {
                    content: url('../assets/images/arrow-icon.svg');
                    margin-right: 1.5rem;
                    position: relative;
                    top: -1px;
                }
                &:hover {
                    text-decoration: underline!important;
                }
            }
        }
    }
    @include media-breakpoint-only(lg) {
    	#hs-eu-cookie-confirmation-inner {
    		max-width: 1070px!important;
    	}
    }
	@include media-breakpoint-only(md) {
    	#hs-eu-cookie-confirmation-inner {
    		max-width: 100%!important;
    	}
    }
    @include media-breakpoint-between(xs,sm) {
    	#hs-eu-cookie-confirmation-inner {
    		max-width: unset!important;
    		margin: 0 15px!important;
    	}
    }
    @media(max-width: 960px) {
        bottom:0;
        position: fixed !important;
    }
}

@media(max-width: 960px) {
    html,body {
        div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
            padding-top: 28px!important;
        }
        div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-en-cookie-confirmation-buttons-area {
            text-align: left !important;
        }
        #hs-eu-cookie-confirmation #hs-eu-cookie-confirmation-inner #hs-en-cookie-confirmation-buttons-area #hs-eu-confirmation-button {
            padding-left: 0 !important;
        }
    }
}

#breadcrumbs {
    margin: 2rem 0 0;
    font-size: 1.3rem;
    a {
        color: inherit;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
        &.breadcrumb_last {
            text-decoration: none;
        }
    }

    @include media-breakpoint-between(xs,md) {
         margin: 2rem 0;
    }
}

.btn {
    appearance: none;
    border: none;
    font-family: $font-family;
    background-color: $bg-gold;
    display: inline-flex;
    align-items: center;
    font-size: 17px;
    line-height: 1;
    color: $font-clr;
    font-weight: 500;
    padding: 1.5rem 3.5rem;
    margin-left: 1rem;
    margin-top: 2.5rem;
    position: relative;
    &:before {
        content: url('../assets/images/arrow-icon.svg');
        margin-right: 1.5rem;
    }
    &--reverse-arrow:before {
        transform: rotate(180deg) translateY(-1px);
    }
    &:first-of-type {
        margin-left: 0;
    }
    &:hover {
        // color: inherit;
        color: white;
        background: $font-clr;
        text-decoration: none;
        &:before {
            content: url('../assets/images/arrow-icon-white.svg');
        }
    }

    &--download {
        &:before {
            display: none;
        }
        &:after {
            content: url('../assets/images/download-icon.svg');
            margin-left: 1.5rem;
        }
        &:hover {
            &:after {
                content: url('../assets/images/download-icon-white.svg');
            }
        }
    }

    &--tab {
        background-color: white;
        border: 1px solid $font-clr;
        &:before {
            display: none;
        }
        &.active {
            background-color: $bg-gold;
            border: 1px solid $bg-gold;
        }
        &:hover {
            background-color: $font-clr;
            border: 1px solid $font-clr;
        }
    }
    @include media-breakpoint-between(xs,md) {
        margin-top: 2rem;
        padding: 1.5rem 2.5rem;
    }
}

.job-buttons {
    display: flex;
    margin: 4rem 0 2rem 0;
    .btn {  
        margin: 0;
    }
    .btn-love {
        appearance: none;
        border: none;
        padding: 0;
        width: 47px;
        height: 47px;
        display: flex;
        align-items: center;
        margin-left: 2rem;
        svg {
            display: block;
            margin: auto;
        }
    }
}

.link {
    appearance: none;
    background-color: transparent;
    border: none;
    font-family: $font-family;
    display: inline-flex;
    font-size: 17px;
    line-height: 1;
    color: $font-clr;
    font-weight: 500;
    padding: 1rem 0;
    position: relative;
    text-decoration: none;

    &:before {
        content: url('../assets/images/arrow-icon.svg');
        margin-right: 1.5rem;
        position: relative;
        top: -1px;
    }
    &:first-of-type {
        margin-left: 0;
    }
    &:hover {
        color: inherit;
        text-decoration: underline;
    }

    &--plus {
        &:before {
            content: '+';
            top: 0;
        }
    }

    &--minus {
        &:before {
            content: '-';
            top: 0;
        }
    }
    @include media-breakpoint-between(xs,sm) {
        font-size: 15px;
    }

}

html[data-useragent*="rv:11.0"] {
    .link,
    .btn {
        &:before
        &:after {
            display: none !important;
        }
    }
}

// OBJECTFIT
// -----------------------------------------------------------------------------
div {
    &.objectfit {
        height: 100%;
        img {
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}
.compat-object-fit {
    background-size: cover;
    background-position: center center;
    img {
        opacity: 0 !important;
    }
}

svg.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    //fill: currentColor;
}

.skiplink {
    position: absolute;
    left: -9999em;
    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        font-size: 2rem;
        z-index: 1000;
        background: #000000;
        padding: 1rem 1.8rem;
        border: 4px solid white;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}