//******************************************************************************
// PAGES
// Common styles shared by multiple pages
// Unless you're working on very large site or application, most of the
// page-specific code should go in here.
//******************************************************************************

// General 
.page-default-content {
	margin-top: 7rem;
    .pull-out {
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    ul {
        padding-left: 18px;
    }
    h5 {
        font-family: $font-family;
        font-size: 1.5rem;
        font-weight: 500;
        margin-top: 4rem;
        margin-bottom: 1rem;
    }

    @include media-breakpoint-between(xs, md) {
        margin-top: 5rem; // Where the first item is a <p> this may appear larger, but 5rem is standard for all page builder elements
    }
}

// Homepage
.home {
    .slick-slider {
        .video-container,
        .objectfit {
            height: unset;
        }
        .pb-hero__left {
            padding-top: 16rem;
            padding-bottom: 16rem;
        }

        h1,
        h2 {
            margin-top: 0;
        }

        .hero__left-img {
            position: absolute; 
            top: 0; 
            left: 0;
            height: 100%;
            width: 43%;
            display: flex;
            align-items: center;
            img {
                margin: auto;
            }
        }
        .hero__right-img {
            position: absolute; 
            top: 0; 
            right: 0;
            height: 100%;
            width: 57%;
        }
        .objectfit {
            height: 100%;
            position: relative;

            img {
                position: absolute;
            }
        }
    }

	.overlap {
        margin-bottom: 9rem;
        h2 {
            line-height: 1;
        }
        .overlap__negative,
        .overlap__content {
            z-index: 5;
        }
        .overlap__negative {
            position: relative;
            bottom: -9rem;
        }
        .overlap__content {
            padding-top: 9rem;
            padding-bottom: 9rem;
        }
        .container {
            position: relative;
        }

        &__background {
            flex: 0 0 25%;
            max-width: 25%; 

            .clip {
                position: relative;
                z-index: 1;
            }
        }
        &__image {
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            overflow: hidden;
            z-index: 0;

            img {
                margin: auto;
            }
        }
    }

    #sb_instagram {

        .sb_instagram_header {
            overflow: visible !important;
            padding: 0 !important;
            margin-bottom: 2.2rem !important;
        }

        .sbi_header_img {
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
        }

        .sbi_header_link,
        .sbi_header_link:hover {
            color: #3A3131 !important;
        }

        #sbi_images {
            padding: 0 !important;
        }

    }

    @include media-breakpoint-only(md) {
    	.slick-slider {
            .pb-hero__left {
                margin-bottom: 400px;
                min-height: 400px;
            }
            .hero__left-img {
                img {
                    max-height: 400px;
                }
            }
            .hero__right-img {
                height: 400px;
            }
        }
        .overlap {
            .overlap__content {
                margin-bottom: 0;
            }
            &__image {
                top: auto;
                bottom: 0;
            }
        }
    }

    @include media-breakpoint-between(xs, md) {
        .slick-slider {
            .pb-hero__left {
                padding-top: 9rem;
                padding-bottom: 9rem;
            }

            h1,
            h2 {
                margin-top: 0;
            }

            .hero__left-img {
                width: 100%;
                height: unset;
            }
            .hero__right-img {
                position: absolute;
                width: 100%;
                bottom: 0;
                top: auto;
            }
        }
        .overlap {
            .overlap__content {
                padding-bottom: 0;
            }
            &__background {
                flex: 0 0 40%;
                max-width: 40%; 
            }
            &__image {
                height: auto;
            }
        }
    }

    @include media-breakpoint-between(xs, sm) {
    	.slick-slider {
            .pb-hero__left {
                margin-bottom: 300px;
                padding-top: 5rem;
                padding-bottom: 5rem;
            }
            .hero__left-img {
                img {
                    max-height: 300px;
                }
            }
            .hero__right-img {
                height: 300px;
            }
        }
        .overlap {
            margin-bottom: 0;
            .overlap__negative {
                bottom: 0;
            }
            .overlap__content {
                padding-top: 5rem;
                padding-bottom: 0;
            }
            &__background {
                position: relative;
                max-width: 100%;
                width: 100%;
                flex: unset;
                padding: 18rem 1.5rem;
                overflow: hidden;

                .clip {
                    position: relative;
                    z-index: 1;
                    max-height: 300px;
                }
            }
            &__image {
                width: 80%;
                top:0;
            }
        }
    }

    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (-webkit-min-device-pixel-ratio: 1) {
        .post-previews {
            margin-bottom: 4rem;
        }
    }
}

// Contact
.page-template-template-contact {
    .pb-hero__left {
        h1 {
            margin-top: 0;
        }
        h5 {
            margin-bottom: 1rem;
        }
    }
    .pb-hero__right {
        min-height: 520px;
        .objectfit {
            position: absolute;
            display: block;
            top: 0;
            height: 100%;
            width: 100%;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
    form {
        .vertical_radio {
             .frm_primary_label {
                position: absolute;
             }
        }
    } 
    .frm_message {
        padding: 0; 
    }
    .article-grid__inner { 
        padding: 4rem 5rem 6rem 5rem; 
        h4 {
                margin-bottom: 1rem;
        }
    }

    /* Portrait and Landscape */
    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (-webkit-min-device-pixel-ratio: 1) {
        .frm_button_submit {
            position: absolute;
            bottom: 5rem;
        }
    }
}

.contact-hero-area {
    &__bg {
        background-color: $bg-stone;
        height: 50rem;
        width: 100%;
        position: absolute;
        z-index: -1;
        top: 12rem;
    }

    @include media-breakpoint-down(md) {
        &__bg {
            width: calc(100% + 15px);
            top: 9rem;
        }
    }
}

.contact-detail-blocks {
    margin-top: 26rem;
    position: relative;

    @media(max-width: 1123px) {
        margin-top: 0rem;
    }

    &__intro {
        padding-bottom: 6.5rem;
    }

	&__masonry-area {
		display: none;

		&--show {
			display: flex !important;
		}
	}

	&__grid-sizer {
		@include make-col-ready();
		@include media-breakpoint-up(sm) {
			@include make-col(12);
		}
		@include media-breakpoint-up(md) {
			@include make-col(6);
		}
		@include media-breakpoint-up(lg) {
			@include make-col(4);
		}
	}

	&__item {
		position: relative;
		z-index: 2;
	}
}

// Form
.page-template-template-form-info:not(.is-alumni),
.page-template-template-login-registration,
.page-template-template-staff-profle-form {
    .pb-hero {
        min-height: 400px;
    }
	.overlap { 
        padding-top: 14rem;
        .overlap__negative {
            position: relative;
            top: -44rem;
            margin-bottom: -42rem;
        }
    }
    @include media-breakpoint-between(xs,md) {
        .pb-hero {
            min-height: auto;
        }
        .overlap {
            padding-top: 5rem;
            .overlap__negative {
                margin-top: 0;
                top: 0;
            	margin-bottom: 0;
            }
        }
    }
}

.page-template-template-login-registration {
    .pb-hero.pb-hero {
        min-height: 0;

        .pb-hero__left {
            padding-bottom: 5rem;
        }
    }

    .overlap .overlap__negative {
        top: -22rem;
        margin-bottom: -22rem;
    }

    @include media-breakpoint-between(xs,md) {
        .pb-hero {
            min-height: auto;
        }
        .overlap {
            padding-top: 5rem;
            .overlap__negative {
                margin-top: 0;
                top: 0;
                margin-bottom: 0;
            }
        }
    }
}

// Single
//single-student-jobs
.team-members-template-default,
.my-norland-story-template-default {
	.overlap {
        &:not(.bg-white) {
            margin-top: 18rem;
        }
        .overlap__negative {
            position: relative;
			top: -18rem;
			margin-bottom: -18rem;
        }
    }
    @include media-breakpoint-only(md) {
        .overlap {
            &:not(.bg-white) {
                margin-top: 18rem;
            }
        }
    }
    @include media-breakpoint-between(xs, sm) {
        .overlap {
            a[href^="mailto"] {
                word-break: break-word;
            }

    		.overlap__negative {
                top: 0;
            	margin-bottom: 3rem;
            }
        }
    }
}


// Search
.search-no-results,
.search-results {
    article {
        margin-bottom: 7rem;
    }
    .overlap {
        margin-top: 20rem;
        .cards {
            margin-top: 0;
        }
        .overlap__negative {
            position: relative;
            top: -15rem;
            margin-bottom: -15rem;
        }
        @include media-breakpoint-between(xs, sm) {
            margin-top: 0;
            padding-top: 5rem;
            .overlap__negative {
                top: 0;
                margin-bottom: 3rem;
            }
        }
    }
    .overlap {
        padding-bottom: 2rem;
    }
    .looking-for-more {
        margin-top: 9rem;
        padding-top: 9rem;
        padding-bottom: 9rem;
        .text-center {
            h2 {
                margin-bottom: 2rem;
            }
            &:before {
                content: '';
                display: block;
                height: 1px;
                background-color: $font-clr;
                width: 100%;
                position: relative;
                top: -9rem;
                opacity: 0.3;
            }
        }
    }

    @include media-breakpoint-only(md) {
        .looking-for-more {
            margin-top: 0;
            padding-top: 18rem;
            padding-bottom: 6rem;
            .text-center {
                &:before {
                    top: -6rem;
                }
            }
        }
    }

    @include media-breakpoint-between(xs,sm) {
        article {
            margin-bottom: 5rem;
        }
        .looking-for-more {
            margin-top: 5rem;
            padding-top: 5rem;
            padding-bottom: 5rem;
            .text-center {
                &:before {
                    top: -5rem;
                }
            }
        }
    }
}

.post-type-archive-team-members,
.post-type-archive-my-norland-story {
    .article-filter {
        &__filters {
            @include media-breakpoint-only(md) {
                ul {
                    li {
                        width: 52%;
                        &:last-of-type {
                            width: 48%;
                        }
                    }
                }
                select {
                    min-width: unset;
                    width: 100%;
                }
                label {
                    width: 100%;
                }
            }
            @include media-breakpoint-between(xs,sm) {
                ul {
                    li {
                        width: 100%;
                        padding-right: 0;
                    }
                }
                select {
                    min-width: unset;
                    width: 100%;
                }
                label {
                    width: 100%;
                }
            }
        }
    }
}

.post-type-archive-policies-reports {
    .article-filter {
        &__filters {
            @include media-breakpoint-only(md) {
                ul {
                    li {
                        &:nth-child(2) {
                            padding-right: 0;
                        }
                        &:last-of-type {
                            width: 100%;
                        }
                    }
                }
                label {
                    min-width: 332px;
                    width: 100%;
                }
            }

            @include media-breakpoint-between(xs,sm) {
                ul {
                    li {
                        width: 100%;
                        padding-right: 0;
                    }
                }
                select {
                    min-width: unset;
                    width: 100%;
                }
                label {
                    width: 100%;
                }
            }
        }
    }
}